import React from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { Provider } from "react-redux";
import Layout from "./hocs/Layout";
import HomePage from "./containers/HomePage";
import NotFound from "./containers/NotFound";
import UbiHouseListe from "./containers/UbiHouseListe";
import UbiEnergyListe from "./containers/UbiEnergyListe";
import UbiTray from "./containers/UbiTray";
import Hakkimizda from "./containers/Hakkimizda";
import ResetPasswordConfirm from "./containers/Auth/ResetPasswordConfirm";
import ResetPassword from "./containers/Auth/ResetPassword";
import ResetPasswordInfo from "./containers/Auth/ResetPasswordInfo";
import Iletisim from "./containers/Iletisim";
import Login from "./containers/Auth/Login";
import Signup from "./containers/Auth/Signup";
import Activate from "./containers/Auth/Activate";
import Google from "./containers/Auth/Google";
import Facebook from "./containers/Auth/Facebook";
import ProductDetail from "./containers/ProductDetail";
import Cart from "./containers/Cart";
import goToCart from "./containers/GoToCart";
import Checkout from "./containers/Checkout";
import Dashboard from "./containers/Auth/Dashboard";
import OrderItemDetail from "./containers/OrderItemDetail";
import ThankYou from "./containers/ThankYou";
import DeviceDetail from "./containers/DeviceDetail";
import ChatBot from "./components/ChatBot";

import "./App.css";
import store from "./store";
import PrivateRoute from "./hocs/PrivateRoute";

const App = () => (
  <Provider store={store}>
    <Router>
      <Layout>
        <ChatBot />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/sepetim" component={Cart} />
          <Route exact path="/cart-or-continue-shopping" component={goToCart} />
          <PrivateRoute exact path="/checkout" component={Checkout} />
          <PrivateRoute exact path="/thankyou" component={ThankYou} />
          <Route exact path="/product/:id" component={ProductDetail} />
          <Route exact path="/device/:id" component={DeviceDetail} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute
            exact
            path="/dashboard/order-detail/:transaction_id"
            component={OrderItemDetail}
          />
          <Route exact path="/activate/:uid/:token" component={Activate} />
          <Route exact path="/google" component={Google} />
          <Route exact path="/facebook" component={Facebook} />
          <Route exact path="/ubihouse" component={UbiHouseListe} />
          <Route exact path="/ubienergy" component={UbiEnergyListe} />
          <Route exact path="/ubitray" component={UbiTray} />
          <Route exact path="/hakkimizda" component={Hakkimizda} />
          <Route exact path="/iletisim" component={Iletisim} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route
            exact
            path="/reset-password-info"
            component={ResetPasswordInfo}
          />
          <Route
            exact
            path="/password/reset/confirm/:uid/:token"
            component={ResetPasswordConfirm}
          />
          <Route exact path="/not-found" component={NotFound} />
          <Redirect to="not-found" />
        </Switch>
      </Layout>
    </Router>
  </Provider>
);

export default App;
