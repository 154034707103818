import axios from "axios";
import { setAlert } from "./alert";
import {
  ADD_ITEM,
  GET_TOTAL,
  GET_ITEM_TOTAL,
  GET_ITEMS,
  UPDATE_ITEM,
  REMOVE_ITEM,
  EMPTY_CART,
  ADD_ITEM_SUCCESS,
  ADD_ITEM_FAIL,
  GET_TOTAL_SUCCESS,
  GET_TOTAL_FAIL,
  GET_ITEM_TOTAL_SUCCESS,
  GET_ITEM_TOTAL_FAIL,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAIL,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAIL,
  REMOVE_ITEM_SUCCESS,
  REMOVE_ITEM_FAIL,
  EMPTY_CART_SUCCESS,
  EMPTY_CART_FAIL,
  SYNCH_CART_SUCCESS,
  SYNCH_CART_FAIL,
} from "./types";

export const add_item = (payload) => async (dispatch, getState) => {
  // First, process chosen_ubi_trays based on purchase method
  let chosen_ubi_trays = payload.chosenUbiTrays || {};

  // For subscriptions, convert to the format the backend expects
  if (payload.purchaseMethod === "subscription") {
    if (!Array.isArray(chosen_ubi_trays)) {
      // Convert object format to array of objects with just id
      chosen_ubi_trays = Object.entries(chosen_ubi_trays)
        .filter(([id, qty]) => qty > 0) // Only keep items with quantity > 0
        .map(([id, qty]) => {
          // For each item in quantity, create a separate entry
          const entries = [];
          // Check if qty is an object with name and quantity properties
          if (typeof qty === "object" && qty.name && qty.quantity) {
            for (let i = 0; i < qty.quantity; i++) {
              entries.push({
                id: parseInt(id, 10),
                name: qty.name,
              });
            }
          } else {
            // If it's just a number or doesn't have name property
            for (let i = 0; i < qty; i++) {
              entries.push({ id: parseInt(id, 10) });
            }
          }
          return entries;
        })
        .flat(); // Flatten the array of arrays
    } else {
      // If it's already an array, ensure the format is correct (only id property)
      chosen_ubi_trays = chosen_ubi_trays.flatMap((item) => {
        if (item.quantity && item.quantity > 1) {
          // Create multiple entries for quantities > 1
          return Array(item.quantity).fill({
            id: item.id,
            name: item.name,
          });
        }
        return {
          id: item.id,
          name: item.name,
        };
      });
    }
  } else {
    // For regular products, keep original format but ensure it's normalized
    if (!Array.isArray(chosen_ubi_trays)) {
      chosen_ubi_trays = Object.entries(chosen_ubi_trays)
        .map(([id, qty]) => {
          // Check if qty is an object with name property
          if (typeof qty === "object" && qty.name) {
            return {
              id: parseInt(id, 10),
              quantity: qty.quantity || qty,
              name: qty.name,
            };
          }
          return {
            id: parseInt(id, 10),
            quantity: qty,
            // We don't have the name here, it will need to be fetched separately
          };
        })
        .filter((tray) => tray.quantity > 0);
    }
  }

  const sorted_trays = [...chosen_ubi_trays].sort((a, b) => a.id - b.id);

  if (localStorage.getItem("access")) {
    // Authenticated branch
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    // Use the converted sorted_trays here
    let body;
    if (payload.purchaseMethod === "subscription") {
      // Build subscription payload.
      const subscriptionPayload = {
        selected_values: payload.selectedValues || {}, // Ensure it's an empty object, not undefined
        purchase_method: "subscription", // Ensure it's always "subscription"
        chosen_ubi_trays: sorted_trays,
        subscription_plan_id: payload.subscriptionPlanId,
      };

      // Include product_id only if it is available (product-based subscription).
      if (payload.productId) {
        subscriptionPayload.product_id = payload.productId;
      }

      body = JSON.stringify(subscriptionPayload);
    } else {
      // One time payment payload.
      body = JSON.stringify({
        product_id: payload.productId,
        selected_values: payload.selectedValues || {}, // Ensure it's an empty object, not undefined
        purchase_method: payload.purchaseMethod,
        chosen_ubi_trays: sorted_trays,
      });
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/cart/add-item`,
        body,
        config
      );

      if (res.status === 201) {
        dispatch({
          type: ADD_ITEM_SUCCESS,
          payload: res.data,
        });
        dispatch(setAlert("Ürün sepetinize eklendi", "success"));
      } else {
        dispatch({
          type: ADD_ITEM_FAIL,
        });
        dispatch(setAlert("Ürün zaten sepetinizde", "warning"));
      }
    } catch (err) {
      dispatch({
        type: ADD_ITEM_FAIL,
      });

      // Show error message from the backend if available
      if (err.response && err.response.data && err.response.data.error) {
        dispatch(setAlert(err.response.data.error, "error"));
      } else {
        dispatch(setAlert("Ürün sepete eklenirken bir hata oluştu", "error"));
      }
    }
  } else {
    // Non-authenticated branch: use localStorage
    let cart = [];
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }

    // Ensure selectedValues is defined.
    let selected_values = payload.selectedValues || {};

    // Generate configuration key using consistent formatting.
    const configuration_key =
      payload.purchaseMethod === "subscription"
        ? `subscription-${payload.subscriptionPlanId}-${JSON.stringify(
            selected_values,
            null,
            0
          )}-${payload.purchaseMethod}-${JSON.stringify(sorted_trays, null, 0)}`
        : `${payload.productId}-${JSON.stringify(selected_values, null, 0)}-${
            payload.purchaseMethod
          }-${JSON.stringify(sorted_trays, null, 0)}`;

    let shouldAddItem = true;
    const newProductId = payload.productId || payload.id;

    // Check for duplicate items.
    cart.forEach((item) => {
      if (
        ((item.product &&
          item.product.id &&
          newProductId &&
          newProductId.toString() === item.product.id.toString()) ||
          (payload.purchaseMethod === "subscription" &&
            item.subscription_plan_id === payload.subscriptionPlanId)) &&
        item.configuration_key === configuration_key
      ) {
        shouldAddItem = false;
      }
    });

    // For subscription, try to fetch the subscription plan details
    let subscription_plan = null;
    if (
      payload.purchaseMethod === "subscription" &&
      payload.subscriptionPlanId
    ) {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/products/subscription-plan/${payload.subscriptionPlanId}`,
          { headers: { Accept: "application/json" } }
        );
        subscription_plan = res.data;
      } catch (err) {
        console.error("Failed to fetch subscription plan details", err);
      }
    }

    // Ensure full product details are available if needed
    let product = null;
    if (!payload.product || Object.keys(payload.product).length === 0) {
      if (payload.productSlug) {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/products/product/${payload.productSlug}`,
            { headers: { Accept: "application/json" } }
          );
          product = res.data;
        } catch (err) {
          console.error(
            "Failed to fetch product details using productSlug",
            err
          );
          product = {};
        }
      } else if (payload.productId) {
        // Try to fetch by ID if slug is not available
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/products/product/${payload.productId}`,
            { headers: { Accept: "application/json" } }
          );
          product = res.data;
        } catch (err) {
          console.error("Failed to fetch product details using productId", err);
          product = {};
        }
      } else {
        console.error(
          "Product identifier not provided in payload. Cannot fetch product details."
        );
        product = {};
      }
    } else {
      product = payload.product;
    }

    // Calculate display information for the cart item
    let formatted_name = "";
    let final_price = 0;

    // Also, try to fetch UbiTray names if they don't have them already
    if (sorted_trays && Array.isArray(sorted_trays)) {
      for (const tray of sorted_trays) {
        if (!tray.name && tray.id) {
          try {
            const res = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/products/product/${tray.id}`,
              { headers: { Accept: "application/json" } }
            );
            if (res.data && res.data.name) {
              tray.name = res.data.name;
            }
          } catch (err) {
            console.error(
              `Failed to fetch name for UbiTray ID ${tray.id}`,
              err
            );
          }
        }
      }
    }

    if (payload.purchaseMethod === "subscription" && subscription_plan) {
      // Format subscription name
      formatted_name = subscription_plan.name || "Abonelik";

      // Add product name if it's a product-specific subscription
      if (product && product.name) {
        formatted_name = `${product.name} - ${formatted_name}`;
      }

      // Calculate price
      final_price = parseFloat(subscription_plan.monthly_price || 0);

      // Add option prices if applicable
      if (selected_values && product && product.options) {
        const extraOptions = [];
        let additional_cost = 0;

        for (const option of product.options) {
          const option_id = option.id.toString();
          if (option_id in selected_values) {
            const value_id = selected_values[option_id];
            const selected_value = option.values.find(
              (v) => v.id.toString() === value_id.toString()
            );

            if (selected_value) {
              const optionText = `${option.name}: ${selected_value.value}`;
              extraOptions.push(optionText);

              // Add cost
              if (
                selected_value.additional_subscription_price &&
                parseFloat(selected_value.additional_subscription_price) > 0
              ) {
                additional_cost += parseFloat(
                  selected_value.additional_subscription_price
                );
              } else if (selected_value.additional_price) {
                additional_cost += parseFloat(selected_value.additional_price);
              }
            }
          }
        }

        // Add options to name if any
        if (extraOptions.length > 0) {
          formatted_name += ` (${extraOptions.join(", ")})`;
        }

        // Add option costs to final price
        final_price += additional_cost;
      }
    } else if (product) {
      // Regular product
      formatted_name = product.name || "";
      // For one-time payments, use regular price (not presale price)
      final_price = parseFloat(product.price || 0);

      // Add option costs and names
      if (selected_values && product.options) {
        const extraOptions = [];
        let additional_cost = 0;

        for (const option of product.options) {
          const option_id = option.id.toString();
          if (option_id in selected_values) {
            const value_id = selected_values[option_id];
            const selected_value = option.values.find(
              (v) => v.id.toString() === value_id.toString()
            );

            if (selected_value) {
              const optionText = `${option.name}: ${selected_value.value}`;
              extraOptions.push(optionText);

              // Add cost
              if (selected_value.additional_price) {
                additional_cost += parseFloat(selected_value.additional_price);
              }
            }
          }
        }

        // Add options to name if any
        if (extraOptions.length > 0) {
          formatted_name += ` (${extraOptions.join(", ")})`;
        }

        // Add option costs to final price
        final_price += additional_cost;
      }
    }

    // Build the order item
    const order_item = {
      product: product,
      productSlug: payload.productSlug,
      count: 1,
      configuration_key: configuration_key,
      selected_values: selected_values,
      purchase_method: payload.purchaseMethod,
      subscription_plan_id: payload.subscriptionPlanId,
      subscription_plan: subscription_plan,
      chosen_ubi_trays: sorted_trays,
      formatted_name: formatted_name,
      final_price: final_price,
    };

    if (shouldAddItem) {
      cart.push(order_item);
    }
    localStorage.setItem("cart", JSON.stringify(cart));
    dispatch({
      type: ADD_ITEM,
      payload: cart,
    });
    dispatch(setAlert("Ürün sepetinize eklendi", "success"));
  }
};

export const get_items = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/cart/cart-items`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: GET_ITEMS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_ITEMS_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ITEMS_FAIL,
      });
    }
  } else {
    // For non-authenticated users, retrieve cart from localStorage.
    let cart = [];
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }

    // For each cart item, if product details are missing, fetch them using productSlug.
    const updatedCart = await Promise.all(
      cart.map(async (item) => {
        if (!item.product || Object.keys(item.product).length === 0) {
          if (item.productSlug) {
            try {
              const res = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/products/product/${item.productSlug}`,
                { headers: { Accept: "application/json" } }
              );

              return {
                ...item,
                product: res.data,
                formatted_name: formatProductName(
                  res.data,
                  item.selected_values,
                  item.purchase_method,
                  item.subscription_plan
                ),
              };
            } catch (err) {
              console.error(
                "Error fetching product details for slug:",
                item.productSlug,
                err
              );
              return item;
            }
          }
        }
        return {
          ...item,
          formatted_name: formatProductName(
            item.product,
            item.selected_values,
            item.purchase_method,
            item.subscription_plan
          ),
        };
      })
    );
    // Update localStorage with the enriched cart data.
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    dispatch({
      type: GET_ITEMS,
      payload: updatedCart,
    });
  }
};

export const get_total = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/cart/get-total`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: GET_TOTAL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_TOTAL_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_TOTAL_FAIL,
      });
    }
  } else {
    // For non-authenticated users, calculate the cart total from localStorage.
    let cart = [];
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }

    let total_cost = 0.0;
    let items_count = 0;
    let one_time_total = 0.0;
    let subscription_monthly_total = 0.0;

    cart.forEach((item) => {
      items_count += item.count || 1;

      if (item.purchase_method === "subscription" && item.subscription_plan) {
        // Get the base subscription cost.
        const subscription_price = parseFloat(
          item.subscription_plan.monthly_price || 0
        );

        // Calculate any additional costs from selected options.
        let additional_subscription_cost = 0.0;
        if (item.selected_values && item.product && item.product.options) {
          for (const option of item.product.options) {
            const option_id = option.id.toString();
            if (option_id in item.selected_values) {
              const value_id = item.selected_values[option_id];
              const selected_value = option.values.find(
                (v) => v.id.toString() === value_id.toString()
              );
              if (selected_value) {
                if (
                  selected_value.additional_subscription_price &&
                  parseFloat(selected_value.additional_subscription_price) > 0
                ) {
                  additional_subscription_cost += parseFloat(
                    selected_value.additional_subscription_price
                  );
                } else if (selected_value.additional_price) {
                  additional_subscription_cost += parseFloat(
                    selected_value.additional_price
                  );
                }
              }
            }
          }
        }

        // Multiply by count and add to the total.
        const item_total =
          (subscription_price + additional_subscription_cost) *
          (item.count || 1);
        subscription_monthly_total += item_total;
        total_cost += item_total;
      } else if (item.product) {
        // Handle regular products.
        let base_price = parseFloat(item.product.price || 0);
        // For one-time payments, use regular price (not presale price)

        // Calculate additional costs from selected options.
        let additional_cost = 0.0;
        if (item.selected_values && item.product.options) {
          for (const option of item.product.options) {
            const option_id = option.id.toString();
            if (option_id in item.selected_values) {
              const value_id = item.selected_values[option_id];
              const selected_value = option.values.find(
                (v) => v.id.toString() === value_id.toString()
              );
              if (selected_value && selected_value.additional_price) {
                additional_cost += parseFloat(selected_value.additional_price);
              }
            }
          }
        }

        // Multiply by count and add to the total.
        const item_total = (base_price + additional_cost) * (item.count || 1);
        one_time_total += item_total;
        total_cost += item_total;
      }
    });

    // Format for backward compatibility but also add new format fields
    const total = {
      one_time_total: one_time_total.toFixed(2),
      subscription_monthly_total: subscription_monthly_total.toFixed(2),

      // New format fields - just use total_cost
      total_cost: total_cost.toFixed(2),
      items_count: items_count,
    };

    dispatch({
      type: GET_TOTAL,
      payload: total,
    });
  }
};

export const get_item_total = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/cart/get-item-total`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: GET_ITEM_TOTAL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_ITEM_TOTAL_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ITEM_TOTAL_FAIL,
      });
    }
  } else {
    let total = 0;
    if (localStorage.getItem("cart")) {
      total = JSON.parse(localStorage.getItem("cart")).length;
    }
    dispatch({
      type: GET_ITEM_TOTAL,
      payload: total,
    });
  }
};

const getProductId = (item) => {
  if (item.product_id !== undefined) {
    return item.product_id;
  }

  if (item && item.product && Object.keys(item.product).length > 0) {
    return item.product.product ? item.product.product.id : item.product.id;
  }

  // Handle category subscriptions
  if (item.purchase_method === "subscription" && item.subscription_plan_id) {
    return `subscription-${item.subscription_plan_id}`; // Use a unique identifier for category subscriptions
  }

  return null; // Return null if no ID can be determined
};

// Helper to generate configuration_key for a cart item
const generateConfigurationKey = (item) => {
  const selected_values = item.selected_values || {};

  // Use whichever key exists: chosen_ubi_trays or chosenUbiTrays
  let chosen_trays = item.chosen_ubi_trays || item.chosenUbiTrays || [];

  // If it's not an array, convert the object to an array while preserving quantity
  if (!Array.isArray(chosen_trays)) {
    chosen_trays = Object.entries(chosen_trays).map(([id, qty]) => ({
      id: parseInt(id, 10),
      quantity: Number(qty),
    }));
  } else {
    // Ensure that for each tray the quantity is preserved
    chosen_trays = chosen_trays.map((tray) => ({
      id: tray.id,
      quantity: tray.quantity,
    }));
  }

  // Sort for stable serialization
  const sorted_trays = [...chosen_trays].sort((a, b) => a.id - b.id);

  return `${item.product.id}-${JSON.stringify(selected_values)}-${
    item.purchase_method
  }-${JSON.stringify(sorted_trays)}`;
};

export const update_item = (item, count) => async (dispatch) => {
  // Ensure configuration_key exists
  if (!item.configuration_key) {
    item.configuration_key = generateConfigurationKey(item);
  }

  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const product_id = getProductId(item);
    const configuration_key = item.configuration_key;

    // Include subscription information if present
    const purchase_method = item.purchase_method;
    const subscription_plan_id = item.subscription_plan_id;

    const body = JSON.stringify({
      product_id,
      count,
      configuration_key,
      purchase_method,
      subscription_plan_id,
    });

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/cart/update-item`,
        body,
        config
      );

      if (res.status === 200 && !res.data.error) {
        dispatch({
          type: UPDATE_ITEM_SUCCESS,
          payload: res.data,
        });
        dispatch(setAlert("Sepetiniz güncellendi", "success"));
      } else {
        dispatch({
          type: UPDATE_ITEM_FAIL,
        });
        dispatch(setAlert("Sepet güncellenemedi", "error"));
      }
    } catch (err) {
      dispatch({
        type: UPDATE_ITEM_FAIL,
      });
      dispatch(setAlert("Sepet güncellenemedi", "error"));
    }
  } else {
    let cart = [];
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
      cart = cart.map((cart_item) => {
        // Ensure the item has a configuration_key for comparison
        if (!cart_item.configuration_key) {
          cart_item.configuration_key = generateConfigurationKey(cart_item);
        }
        if (
          getProductId(cart_item).toString() ===
            getProductId(item).toString() &&
          cart_item.configuration_key === item.configuration_key
        ) {
          return { ...cart_item, count: parseInt(count) };
        }
        return cart_item;
      });
    }
    localStorage.setItem("cart", JSON.stringify(cart));
    dispatch({
      type: UPDATE_ITEM,
      payload: cart,
    });
    dispatch(setAlert("Sepetiniz güncellendi", "success"));
  }
};

export const remove_item = (item) => async (dispatch) => {
  // Ensure configuration_key exists
  if (!item.configuration_key) {
    item.configuration_key = generateConfigurationKey(item);
  }

  if (localStorage.getItem("access")) {
    const product_id = getProductId(item);
    const configuration_key = item.configuration_key;

    // Include subscription information if present
    const purchase_method = item.purchase_method;
    const subscription_plan_id = item.subscription_plan_id;

    const body = JSON.stringify({
      product_id,
      configuration_key,
      purchase_method,
      subscription_plan_id,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      data: body,
    };

    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/cart/remove-item`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: REMOVE_ITEM_SUCCESS,
          payload: res.data,
        });
        dispatch(setAlert("Ürün sepetinizden çıkarıldı", "success"));
      } else {
        dispatch({
          type: REMOVE_ITEM_FAIL,
        });
        dispatch(setAlert("Ürün silinemedi, bir hata oluştu", "error"));
      }
    } catch (err) {
      dispatch({
        type: REMOVE_ITEM_FAIL,
      });
      dispatch(setAlert("Ürün silinemedi, bir hata oluştu", "error"));
    }
  } else {
    let cart = [];
    let new_cart = [];
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
      cart.forEach((cart_item) => {
        if (!cart_item.configuration_key) {
          cart_item.configuration_key = generateConfigurationKey(cart_item);
        }

        const cartItemId = getProductId(cart_item);
        const itemId = getProductId(item);

        if (
          cartItemId !== null &&
          itemId !== null &&
          cartItemId.toString() === itemId.toString() &&
          cart_item.configuration_key === item.configuration_key
        ) {
          // Do not add to new_cart
        } else {
          new_cart.push(cart_item);
        }
      });
    }
    localStorage.setItem("cart", JSON.stringify(new_cart));
    dispatch({
      type: REMOVE_ITEM,
      payload: new_cart,
    });
    dispatch(setAlert("Ürün sepetinizden çıkarıldı", "success"));
  }
};

export const synch_cart = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  let cart_items = [];
  if (localStorage.getItem("cart")) {
    let cart = JSON.parse(localStorage.getItem("cart"));
    cart.forEach((cart_item) => {
      if (!cart_item.configuration_key) {
        cart_item.configuration_key = generateConfigurationKey(cart_item);
      }
      // Include all relevant fields for synchronization
      const itemData = {
        product_id: getProductId(cart_item),
        count: cart_item.count,
        configuration_key: cart_item.configuration_key,
        selected_values: cart_item.selected_values,
        purchase_method: cart_item.purchase_method,
        subscription_plan_id: cart_item.subscription_plan_id,
        chosen_ubi_trays: cart_item.chosen_ubi_trays,
      };
      cart_items.push(itemData);
    });
  }
  const body = JSON.stringify({ cart_items });
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/cart/synch`,
      body,
      config
    );
    if (res.status === 201) {
      dispatch({
        type: SYNCH_CART_SUCCESS,
      });
      dispatch(setAlert("Sepet senkronize edildi", "success"));
    } else {
      dispatch({
        type: SYNCH_CART_FAIL,
      });
      dispatch(setAlert("Sepet senkronize edilemedi", "error"));
    }
  } catch (err) {
    dispatch({
      type: SYNCH_CART_FAIL,
    });
    dispatch(setAlert("Sepet senkronize edilemedi", "error"));
  }
};

export const empty_cart = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/cart/empty-cart`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: EMPTY_CART_SUCCESS,
        });
      } else {
        dispatch({
          type: EMPTY_CART_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: EMPTY_CART_FAIL,
      });
    }
  } else {
    dispatch({
      type: EMPTY_CART,
    });
  }
};

// Utility function to format product names
const formatProductName = (
  product,
  selectedValues,
  purchaseMethod,
  subscriptionPlan
) => {
  // Check both possible structures for the product name
  const productName =
    product.name || (product.product && product.product.name) || "";
  let formattedName = productName;
  const extras = [];
  let hasIklimlendirmeVar = false;

  // If it's a category subscription, add the subscription plan name
  if (
    purchaseMethod === "subscription" &&
    subscriptionPlan &&
    subscriptionPlan.name
  ) {
    formattedName += ` ${subscriptionPlan.name}`;
  }

  // Check if there's an Iklimlendirme option with value "Var"
  if (selectedValues && product.options) {
    for (const option of product.options) {
      const optionId = option.id.toString();
      if (optionId in selectedValues) {
        const valueId = selectedValues[optionId];
        const selectedValue = option.values.find(
          (v) => v.id.toString() === valueId.toString()
        );

        if (selectedValue) {
          if (
            option.name === "İklimlendirme" &&
            selectedValue.value === "Var"
          ) {
            hasIklimlendirmeVar = true;
          } else if (
            selectedValue.value !== "Base" &&
            !(option.name === "İklimlendirme" && selectedValue.value === "Yok")
          ) {
            extras.push(selectedValue.value);
          }
        }
      }
    }
  }

  // Add options to name if any
  if (extras.length > 0) {
    formattedName += ` ${extras.join(" ")}`;
  }

  // Add Iklimlendirmeli if needed
  if (hasIklimlendirmeVar) {
    formattedName += " Iklimlendirmeli";
  }

  return formattedName;
};
