import {
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAIL,
  ADD_SUBSCRIPTION_ITEM_SUCCESS,
  ADD_SUBSCRIPTION_ITEM_FAIL,
  GET_USER_SUBSCRIPTIONS_SUCCESS,
  GET_USER_SUBSCRIPTIONS_FAIL,
  GET_PRODUCT_SUBSCRIPTION_PLANS_SUCCESS,
  GET_PRODUCT_SUBSCRIPTION_PLANS_FAIL,
} from "../actions/types";

const initialState = {
  // Key: productId -> Value: array of subscription plans for that product
  productPlans: {},

  // Hold all subscriptions that belong to the user
  userSubscriptions: [],

  // Keep track of the subscription recently created by the user
  currentSubscription: null,

  // Store any error messages or flags
  error: null,
};

export default function subscriptions(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // ---------------------------
    // 1) GET PRODUCT SUBSCRIPTION PLANS
    // ---------------------------
    case GET_PRODUCT_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        productPlans: {
          ...state.productPlans,
          // For the fetched product, store the array of plans
          [payload.productId]: payload.plans,
        },
        error: null,
      };

    case GET_PRODUCT_SUBSCRIPTION_PLANS_FAIL:
      return {
        ...state,
        error: "Failed to load subscription plans for this product",
      };

    // ---------------------------
    // 2) CREATE SUBSCRIPTION
    // ---------------------------
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        currentSubscription: payload,
        error: null,
      };

    case CREATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: "Failed to create subscription",
      };

    // ---------------------------
    // 3) ADD SUBSCRIPTION ITEM
    // ---------------------------
    case ADD_SUBSCRIPTION_ITEM_SUCCESS:
      if (
        state.currentSubscription &&
        // Only update if the newly added item belongs to the same subscription
        state.currentSubscription.id === payload.user_subscription
      ) {
        // Merge the new item into 'items' array
        // If currentSubscription has 'items', append. If not, create an array.
        const updatedItems = state.currentSubscription.items
          ? [...state.currentSubscription.items, payload]
          : [payload];

        return {
          ...state,
          currentSubscription: {
            ...state.currentSubscription,
            items: updatedItems,
          },
          error: null,
        };
      } else {
        return {
          ...state,
          error: null,
        };
      }

    case ADD_SUBSCRIPTION_ITEM_FAIL:
      return {
        ...state,
        error: "Failed to add subscription item",
      };

    // ---------------------------
    // 4) GET USER SUBSCRIPTIONS
    // ---------------------------
    case GET_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        userSubscriptions: payload,
        error: null,
      };

    case GET_USER_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        error: "Failed to fetch user subscriptions",
      };

    // ---------------------------
    // Default
    // ---------------------------
    default:
      return state;
  }
}
