import React, { Fragment, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { check_coupon } from "../actions/coupons";
import { refresh } from "../actions/auth";
import { get_shipping_options } from "../actions/shipping";
import {
  get_payment_total,
  get_client_token,
  process_payment,
  moka_direct_payment,
} from "../actions/payment";
import { create_order } from "../actions/orders";
import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Snackbar,
  Button,
  Alert,
} from "@mui/material";
import CartItem from "../components/CartItem";
import RenderMokaPayment from "../components/RenderMokaPayment";
import axios from "axios";
import { toast } from "react-hot-toast";

// Image Imports
import UbicroLogo from "../assets/images/logo.png";

//Icon Imports
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SellIcon from "@mui/icons-material/Sell";
import MokaCheckoutForm from "../components/MokaCheckoutForm";
import CloseIcon from "@mui/icons-material/Close";

const steps = ["Sepet Onay", "Adres ve Ödeme"];

const Checkout = ({
  items,
  total_items,
  refresh,
  get_shipping_options,
  shipping,
  get_payment_total,
  get_client_token,
  process_payment,
  moka_direct_payment,
  user,
  profile,
  made_payment,
  totalCost,
  oneTimeTotal,
  subscriptionTotal,
  shippingCost,
  check_coupon,
  coupon,
}) => {
  ///////////////// Address Field Form Data Begin /////////////////
  const [formData, setFormData] = useState({
    full_name: "",
    address_line_1: "",
    city: "",
    district: "",
    telephone_number: "",
    coupon_name: "",
    shipping_id: 1,
  });

  const {
    full_name,
    address_line_1,
    city,
    district,
    telephone_number,
    coupon_name,
    shipping_id,
  } = formData;

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: name === "shipping_id" ? Number(value) : value, // Convert shipping_id to number
    });
  };

  ///////////////// Address Field Form Data End /////////////////

  ///////////////// Moka Form Begin /////////////////
  const [mokaPay, setMokaPay] = useState({
    card_holder: "",
    card_number: "",
    card_expiry_month: "",
    card_expiry_year: "",
    card_cvv: "",
  });

  function mokaOnChange(e) {
    setMokaPay({ ...mokaPay, [e.target.name]: e.target.value });
  }

  const {
    card_holder,
    card_number,
    card_expiry_month,
    card_expiry_year,
    card_cvv,
  } = mokaPay;
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  ///////////////// Moka Form End /////////////////

  const [activeStep, setActiveStep] = useState(0);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [buttonLabel, setButtonLabel] = useState("Tamam");

  const handleClose = () => {
    setShow(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        {buttonLabel}
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  function Popup() {
    return (
      <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={() => setShow(false)}
        action={action}
      >
        <Alert onClose={() => setShow(false)} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    );
  }

  // Send payment via Moka
  const submitPayment = async (e) => {
    e.preventDefault();
    setSubmitButtonLoading(true);

    // Check if there are subscription items in the cart
    const hasSubscription =
      items && items.some((item) => item.purchase_method === "subscription");

    if (hasSubscription) {
      // Alert the user that their card will be stored securely for future subscription payments
      setMessage(
        "Bu siparişinizde abonelik ürünleri bulunmaktadır. Kredi kartı bilgileriniz güvenli bir şekilde saklanacak ve abonelik ödemeleri periyodik olarak gerçekleştirilecektir."
      );
      setSeverity("info");
      setShow(true);

      // Give the user time to read the message before proceeding
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    // Continue with payment processing
    if (shipping_id !== 0) {
      // Validate customer information
      if (
        !full_name ||
        !address_line_1 ||
        !telephone_number ||
        !city ||
        !district
      ) {
        setSubmitButtonLoading(false);
        setMessage(
          "Lütfen tüm adres bilgilerini doldurun (isim, adres, telefon, şehir, ilçe)"
        );
        setSeverity("error");
        setShow(true);
        return;
      }

      if (
        card_holder !== "" &&
        card_number !== "" &&
        card_expiry_month !== "" &&
        card_expiry_year !== "" &&
        card_cvv !== ""
      ) {
        try {
          if (coupon && coupon !== null && coupon !== undefined) {
            await moka_direct_payment(
              shipping_id,
              coupon.name,
              card_holder,
              card_number,
              card_expiry_month,
              card_expiry_year,
              card_cvv,
              full_name,
              address_line_1,
              telephone_number,
              city,
              district
            );
          } else {
            await moka_direct_payment(
              shipping_id,
              "",
              card_holder,
              card_number,
              card_expiry_month,
              card_expiry_year,
              card_cvv,
              full_name,
              address_line_1,
              telephone_number,
              city,
              district
            );
          }
          setSubmitButtonLoading(false);
        } catch (error) {
          setSubmitButtonLoading(false);
          const errorMessage =
            error.response?.data?.error ||
            "Ödeme işlemi sırasında bir hata oluştu. Lütfen tekrar deneyin.";

          console.log("Captured Error Message:", errorMessage);
          setMessage(errorMessage);
          setSeverity("error");
          setShow(true);
        }
      } else {
        setSubmitButtonLoading(false);
        setMessage("Lütfen kart bilgilerinizi girin");
        setSeverity("error");
        setShow(true);
      }
    } else {
      setSubmitButtonLoading(false);
      setMessage("Lütfen kargo seçimi yapın");
      setSeverity("error");
      setShow(true);
    }
  };

  ///////////////// Fill User Saved Info Begin /////////////////
  useEffect(() => {
    if (
      profile &&
      profile !== null &&
      profile !== undefined &&
      user &&
      user !== null &&
      user !== undefined
    ) {
      setFormData({
        ...formData,
        full_name: user.first_name + " " + user.last_name,
        address_line_1: profile.address_line_1,
        city: profile.city,
        district: profile.district,
        telephone_number: profile.phone,
      });
    }
  }, [profile, user]);
  ///////////////// Fill User Saved Info End /////////////////

  const apply_coupon = async (e) => {
    e.preventDefault();

    check_coupon(coupon_name);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    refresh();
    get_shipping_options();

    // Ensure payment totals are calculated after refresh
    setTimeout(() => {
      get_payment_total(0, "");
    }, 500);
  }, []);

  useEffect(() => {
    get_client_token();
  }, [user]);

  useEffect(() => {
    if (shipping_id !== 0) {
      if (coupon !== null && coupon !== undefined) {
        get_payment_total(shipping_id, coupon.name);
      } else {
        get_payment_total(shipping_id, "");
      }
    }
  }, [shipping_id, coupon]);

  // Add debugging for received props
  useEffect(() => {
    console.log("Payment props received:", {
      totalCost,
      oneTimeTotal,
      subscriptionTotal,
      shippingCost,
      typeOfTotalCost: typeof totalCost,
      typeOfOneTimeTotal: typeof oneTimeTotal,
      typeOfSubscriptionTotal: typeof subscriptionTotal,
      typeOfShippingCost: typeof shippingCost,
    });
  }, [totalCost, oneTimeTotal, subscriptionTotal, shippingCost]);

  const showItems = () => {
    return (
      <div>
        <Typography gutterBottom variant="h4" style={{ margin: 10 }}>
          Sepetinizde {total_items} ürün var
        </Typography>
        <Divider />
        {items &&
          items !== null &&
          items !== undefined &&
          items.length !== 0 &&
          items.map((item, index) => {
            let count = item.count;

            return (
              <div key={index}>
                <CartItem
                  item={item}
                  count={count}
                  showViewProduct={false}
                  showRemoveProduct={false}
                  showUpdateProduct={false}
                  showQuantity
                />
              </div>
            );
          })}
      </div>
    );
  };

  const renderShipping = () => {
    if (shipping && shipping !== null && shipping !== undefined) {
      return (
        <div>
          {shipping.map((shipping_option, index) => (
            <Grid
              container
              direction="row"
              alignItems="center"
              key={index}
              style={{ paddingLeft: 20, paddingTop: 5 }}
            >
              <Radio
                onChange={(e) => onChange(e)}
                value={shipping_option.id}
                name="shipping_id"
                type="radio"
                required
              />
              <Typography>
                {shipping_option.name} -{" "}
                {shipping_option.price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                TL ({shipping_option.time_to_delivery})
              </Typography>
            </Grid>
          ))}
        </div>
      );
    }
  };

  const displayTotal = () => {
    // Check if there are subscription items
    const subscriptionItems = items
      ? items.filter((item) => item.purchase_method === "subscription")
      : [];
    const regularItems = items
      ? items.filter((item) => item.purchase_method !== "subscription")
      : [];

    const hasSubscriptions = subscriptionItems.length > 0;

    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#F4F4F4",
            height: 60,
            margin: 10,
            marginTop: 30,
            width: "95%",
            borderRadius: 10,
          }}
        >
          <Typography
            variant="subtitle1"
            style={{ marginLeft: 10, fontWeight: "bold", color: "#626262" }}
          >
            Ürünler
          </Typography>
          <div style={{ flex: 1, minWidth: 20 }} />
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", marginRight: 30, color: "#626262" }}
          >
            {typeof oneTimeTotal === "number"
              ? oneTimeTotal.toFixed(2)
              : (parseFloat(oneTimeTotal) || 0).toFixed(2)}{" "}
            TL
          </Typography>
        </div>

        {hasSubscriptions && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#F4F4F4",
              height: 60,
              margin: 10,
              width: "95%",
              borderRadius: 10,
            }}
          >
            <Typography
              variant="subtitle1"
              style={{ marginLeft: 10, fontWeight: "bold", color: "#626262" }}
            >
              Abonelik Ürünleri
            </Typography>
            <div style={{ flex: 1, minWidth: 20 }} />
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold", marginRight: 30, color: "#626262" }}
            >
              {typeof subscriptionTotal === "number"
                ? subscriptionTotal.toFixed(2)
                : (parseFloat(subscriptionTotal) || 0).toFixed(2)}{" "}
              TL/Ay
            </Typography>
          </div>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#F4F4F4",
            height: 60,
            margin: 10,
            width: "95%",
            borderRadius: 10,
          }}
        >
          <Typography
            variant="subtitle1"
            style={{ marginLeft: 10, fontWeight: "bold", color: "#626262" }}
          >
            Kargo
          </Typography>
          <div style={{ flex: 1, minWidth: 20 }} />
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", marginRight: 30, color: "#626262" }}
          >
            {typeof shippingCost === "number"
              ? shippingCost.toFixed(2)
              : (parseFloat(shippingCost) || 0).toFixed(2)}{" "}
            TL
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#F4F4F4",
            height: 60,
            margin: 10,
            width: "95%",
            borderRadius: 10,
          }}
        >
          <Typography
            variant="subtitle1"
            style={{ marginLeft: 10, fontWeight: "bold", color: "#626262" }}
          >
            Toplam
          </Typography>
          <div style={{ flex: 1, minWidth: 20 }} />
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", marginRight: 30, color: "#626262" }}
          >
            {typeof totalCost === "number"
              ? totalCost.toFixed(2)
              : (parseFloat(totalCost) || 0).toFixed(2)}{" "}
            TL
          </Typography>
        </div>

        {hasSubscriptions && (
          <Typography
            variant="body2"
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              color: "#626262",
              fontStyle: "italic",
            }}
          >
            * Abonelik ürünleriniz için ilk ödemeniz toplam tutara dahildir.
            Sonraki ödemeler aylık periyotlarla otomatik olarak alınacaktır.
          </Typography>
        )}
      </div>
    );
  };

  const renderMokaPaymentForm = () => {
    return (
      <MokaCheckoutForm
        full_name={full_name}
        address_line_1={address_line_1}
        city={city}
        district={district}
        telephone_number={telephone_number}
        onChange={onChange}
        buy={buy}
        renderShipping={renderShipping}
        user={user}
        profile={profile}
      />
    );
  };

  const renderMokaCreditCardForm = () => {
    return (
      <RenderMokaPayment
        card_holder={card_holder}
        card_number={card_number}
        card_expiry_month={card_expiry_month}
        card_expiry_year={card_expiry_year}
        card_cvv={card_cvv}
        submitPayment={submitPayment}
        mokaOnChange={mokaOnChange}
        submitButtonLoading={submitButtonLoading}
      />
    );
  };

  // Send address fields to backend & send the order to backend then redirect to thank you page
  const buy = async (e) => {
    if (coupon && coupon !== null && coupon !== undefined) {
      process_payment(
        shipping_id,
        coupon.name,
        full_name,
        address_line_1,
        city,
        district,
        telephone_number
      );
    } else {
      process_payment(
        shipping_id,
        "",
        full_name,
        address_line_1,
        city,
        district,
        telephone_number
      );
    }
  };

  let width = window.innerWidth;

  // At the beginning of the component, after the useState declarations
  // Add a computed property to check for subscription items
  const hasSubscriptions =
    items && items.some((item) => item.purchase_method === "subscription");

  // Separate subscription items from regular items for display
  const subscriptionItems = items
    ? items.filter((item) => item.purchase_method === "subscription")
    : [];
  const regularItems = items
    ? items.filter((item) => item.purchase_method !== "subscription")
    : [];

  if (width > 800) {
    return (
      <div>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: 30, marginBottom: 100 }}
        >
          {Popup()}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ maxWidth: 800 }}
          >
            <IconButton href="/" style={{ marginLeft: 30, borderRadius: 10 }}>
              <img
                src={UbicroLogo}
                alt="logo"
                className="logo"
                style={{ marginBottom: 10 }}
              />
              <Typography
                style={{
                  fontSize: 24,
                  marginLeft: 20,
                  fontWeight: 200,
                }}
              >
                Ubicro
              </Typography>
            </IconButton>
            <Stepper activeStep={1} style={{ marginRight: 30, marginLeft: 30 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              style={{ maxWidth: 800, marginTop: 70 }}
            >
              <Typography style={{ marginLeft: 30 }}>
                Teslimat Bilgileriniz
              </Typography>
              <Typography style={{ marginRight: 30 }}>
                Toplam Tutar:{" "}
                {typeof totalCost === "number"
                  ? totalCost.toFixed(2)
                  : (parseFloat(totalCost) || 0).toFixed(2)}{" "}
                TL
              </Typography>
            </Grid>
            <Divider
              style={{
                marginTop: 10,
                marginBottom: 50,
                width: "100%",
                maxWidth: 800,
              }}
            />
            <Grid
              container
              direction="column"
              style={{ maxWidth: 800, marginLeft: 30, marginBottom: 30 }}
            >
              <Typography gutterBottom variant="h4">
                Sipariş Özeti
              </Typography>
              <Grid>{displayTotal()}</Grid>
            </Grid>
            <Divider
              style={{
                marginTop: 10,
                marginBottom: 50,
                width: "100%",
                maxWidth: 800,
              }}
            />
            <Grid
              item
              container
              direction="column"
              style={{ maxWidth: 800, marginLeft: 30 }}
            >
              <Typography variant="h5">Kupon kodu</Typography>
              <form onSubmit={(e) => apply_coupon(e)}>
                <Grid container direction="row" alignItems="center">
                  <TextField
                    name="coupon_name"
                    type="text"
                    label="İndirim kodu"
                    onChange={(e) => onChange(e)}
                    value={coupon_name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SellIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton color="primary" type="submit">
                            <ArrowForwardIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    style={{ marginBottom: 30, marginTop: 30, width: 350 }}
                  />
                  {coupon &&
                  coupon.discount_price &&
                  coupon !== null &&
                  coupon !== undefined ? (
                    <Typography style={{ color: "green", marginLeft: 30 }}>
                      {coupon.discount_price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      TL indirim işlendi.
                    </Typography>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {coupon &&
                  coupon.discount_percentage &&
                  coupon !== null &&
                  coupon !== undefined ? (
                    <Typography style={{ color: "green", marginLeft: 30 }}>
                      % {coupon.discount_percentage} indirim işlendi.
                    </Typography>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </Grid>
              </form>
            </Grid>
            <Divider
              style={{
                marginTop: 10,
                marginBottom: 50,
                width: "100%",
                maxWidth: 800,
              }}
            />
            {renderMokaPaymentForm()}
            {renderMokaCreditCardForm()}
            {showItems()}
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: 30, marginBottom: 100 }}
        >
          {Popup()}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ maxWidth: 800 }}
          >
            <IconButton href="/" style={{ marginLeft: 30, borderRadius: 10 }}>
              <img
                src={UbicroLogo}
                alt="logo"
                className="logo"
                style={{ marginBottom: 10 }}
              />
              <Typography
                style={{
                  fontSize: 24,
                  marginLeft: 20,
                  fontWeight: 200,
                }}
              >
                Ubicro
              </Typography>
            </IconButton>

            <Stepper
              activeStep={1}
              style={{ marginRight: 30, marginLeft: 30, marginTop: 20 }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Divider
              style={{
                marginTop: 30,
                marginBottom: 50,
                width: "100%",
                maxWidth: 800,
              }}
            />

            <Grid
              container
              direction="column"
              style={{ maxWidth: 800, marginLeft: 30, marginBottom: 30 }}
            >
              <Typography gutterBottom variant="h4">
                Sipariş Özeti
              </Typography>
              <Grid>{displayTotal()}</Grid>
            </Grid>

            <Divider
              style={{
                marginTop: 10,
                marginBottom: 50,
                width: "100%",
                maxWidth: 800,
              }}
            />

            <Grid
              item
              container
              direction="column"
              style={{ maxWidth: 800, marginLeft: 30 }}
            >
              <Typography variant="h5">Kupon kodu</Typography>
              <form onSubmit={(e) => apply_coupon(e)}>
                <Grid container direction="row" alignItems="center">
                  <TextField
                    name="coupon_name"
                    type="text"
                    label="İndirim kodu"
                    onChange={(e) => onChange(e)}
                    value={coupon_name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SellIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton color="primary" type="submit">
                            <ArrowForwardIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    style={{ marginBottom: 30, marginTop: 30 }}
                  />
                  {coupon &&
                  coupon.discount_price &&
                  coupon !== null &&
                  coupon !== undefined ? (
                    <Typography style={{ color: "green", marginLeft: 30 }}>
                      {coupon.discount_price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      TL indirim işlendi.
                    </Typography>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {coupon &&
                  coupon.discount_percentage &&
                  coupon !== null &&
                  coupon !== undefined ? (
                    <Typography style={{ color: "green", marginLeft: 30 }}>
                      % {coupon.discount_percentage} indirim işlendi.
                    </Typography>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </Grid>
              </form>
            </Grid>

            <Divider
              style={{
                marginTop: 10,
                marginBottom: 50,
                width: "100%",
                maxWidth: 800,
              }}
            />
            {renderMokaPaymentForm()}
            {renderMokaCreditCardForm()}
            {showItems()}
          </Grid>
        </Grid>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  profile: state.profile.profile,
  items: state.cart.items,
  total_items: state.cart.total_items,
  coupon: state.coupons.coupon,
  shipping: state.shipping.shipping,
  clientToken: state.payment.clientToken,
  made_payment: state.payment.made_payment,
  loading: state.payment.loading,
  totalCost: state.payment.totalCost,
  oneTimeTotal: state.payment.oneTimeTotal,
  subscriptionTotal: state.payment.subscriptionTotal,
  shippingCost: state.payment.shippingCost,
  clientSecret: state.payment.clientSecret,
  create_order_loading: state.orders.loading,
});

export default connect(mapStateToProps, {
  check_coupon,
  create_order,
  refresh,
  get_shipping_options,
  get_payment_total,
  get_client_token,
  process_payment,
  moka_direct_payment,
})(Checkout);
