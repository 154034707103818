import React, { useState, useEffect, Fragment } from "react";
import {
  Typography,
  Grid,
  Button,
  Divider,
  Box,
  useMediaQuery,
  Rating,
  CircularProgress,
} from "@mui/material";
import ImageSlider from "./ProductDetailImageSlider";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import UbiTrayModalSelector from "./UbiTrayModalSelector";

// Import images for Birlikte Gelenler section
import UbiEnergy from "../assets/images/UbiEnergy.jpg";
import UbiTray from "../assets/images/UbiTrayPaket.jpg";

const ProductDetailCardWithOptions = ({
  product,
  add_item,
  get_items,
  get_total,
  get_item_total,
  setRedirect,
  subscriptionPlans = [],
  ubiTrayProducts = [],
  reviews = [],
  reviewsLoading = false,
  averageRating = 0,
  totalRatings = 0,
}) => {
  // 1) Option selection
  const [selectedOptions, setSelectedOptions] = useState({});
  const [chosenUbiTrays, setChosenUbiTrays] = useState({});
  const [availableOptions, setAvailableOptions] = useState({});
  const [totalPrice, setTotalPrice] = useState(product?.price || 0);
  const [lastChangedOptionId, setLastChangedOptionId] = useState(null);
  const [displayImages, setDisplayImages] = useState([]);

  // 2) UbiTray selection
  const [ubiTrayModalOpen, setUbiTrayModalOpen] = useState(false);
  const totalTrayQuantity = Object.values(chosenUbiTrays).reduce(
    (acc, qty) => acc + qty,
    0
  );

  // 3) Payment method
  const [purchaseMethod, setPurchaseMethod] = useState("one_time");

  // 4) Shared button style
  const buttonStyle = {
    borderColor: "#E0E0E0",
    color: "#000",
    borderWidth: 1,
    opacity: 1,
    pointerEvents: "auto",
    minWidth: 170,
    maxWidth: 450,
    minHeight: 70,
    textAlign: "left",
    textTransform: "none",
    justifyContent: "flex-start",
    borderRadius: 15,
  };

  const chosenImage =
    displayImages && displayImages.length > 0
      ? displayImages[0]
      : product?.image || "";

  // ------------------ UbiTray Modal Handlers --------------------
  const handleOpenModal = () => {
    setUbiTrayModalOpen(true);
  };

  const handleCloseModal = (selectedIds, actionType) => {
    setUbiTrayModalOpen(false);
    if (actionType === "save") {
      setChosenUbiTrays(selectedIds);
    }
  };

  // Helper function to get the subscription price for an option value:
  const getOptionSubscriptionPrice = (optionValue) => {
    if (
      optionValue.additional_subscription_price != null &&
      !isNaN(parseFloat(optionValue.additional_subscription_price))
    ) {
      return parseFloat(optionValue.additional_subscription_price);
    }
    return parseFloat(optionValue.additional_price) || 0;
  };

  // ------------------ Subscription Cost Logic --------------------
  const getSubscriptionDetails = () => {
    if (!subscriptionPlans.length || !product || !product.options) return null;

    const basePrice = parseFloat(product.price || 0);
    let subscriptionTotal = basePrice;
    product.options?.forEach((option) => {
      const selectedValId = selectedOptions[option.id];
      if (selectedValId) {
        const selVal = option.values.find((v) => v.id === selectedValId);
        if (selVal) {
          subscriptionTotal += getOptionSubscriptionPrice(selVal);
        }
      }
    });

    const configCost = subscriptionTotal - basePrice;
    const plan = subscriptionPlans[0];
    const monthlyFloat = parseFloat(plan.monthly_price) + configCost;

    return {
      monthly: monthlyFloat.toLocaleString("tr-TR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      months: plan.minimum_months,
      benefits: [
        `${plan.minimum_months} ay boyunca`,
        "Her ay 1 ücretsiz UbiTray",
        "4 ayda bir UbiEnergy",
        "Ücretsiz kargo",
      ],
    };
  };

  // ------------------ Initialize Selected Options ----------------
  useEffect(() => {
    if (product && product.options) {
      const initOptions = {};
      const initAvailable = {};

      product.options?.forEach((option) => {
        initOptions[option.id] = null;
        initAvailable[option.id] = option.values;
      });

      product.options?.forEach((option, index) => {
        if (index === 0) {
          const lowest = option.values
            .filter((v) => v.stock > 0)
            .reduce((lowest, current) => {
              const currPrice = parseFloat(current.additional_price);
              const lowPrice = lowest
                ? parseFloat(lowest.additional_price)
                : Infinity;
              return currPrice < lowPrice ? current : lowest;
            }, null);
          if (lowest) {
            initOptions[option.id] = lowest.id;
          }
        } else {
          const prevId = initOptions[product.options[index - 1].id];
          if (prevId) {
            const valid = option.values.filter(
              (v) =>
                v.stock > 0 &&
                (v.dependencies.length === 0 || v.dependencies.includes(prevId))
            );
            const lowest = valid.reduce((lw, curr) => {
              const c = parseFloat(curr.additional_price);
              const l = lw ? parseFloat(lw.additional_price) : Infinity;
              return c < l ? curr : lw;
            }, null);
            if (lowest) {
              initOptions[option.id] = lowest.id;
            }
          }
        }
      });

      setSelectedOptions(initOptions);

      product.options?.forEach((option, i) => {
        if (i > 0) {
          const prevOptId = product.options[i - 1].id;
          const prevSelectedValue = initOptions[prevOptId];
          if (prevSelectedValue) {
            initAvailable[option.id] = option.values.filter(
              (v) =>
                v.dependencies.length === 0 ||
                v.dependencies.includes(prevSelectedValue)
            );
          }
        }
      });

      setAvailableOptions(initAvailable);
      calculateTotalPrice(initOptions);
    }
  }, [product]);

  // ------------------ Build Display Images -----------------------
  useEffect(() => {
    if (!product) return;

    const baseImgs = [
      product.image,
      product.image_1,
      product.image_2,
      product.image_3,
      product.image_4,
      product.image_5,
      product.image_6,
      product.image_7,
    ].filter(Boolean);

    let selOptionImages = [];
    product.options?.forEach((opt) => {
      const selValId = selectedOptions[opt.id];
      if (selValId) {
        const foundVal = opt.values.find((v) => v.id === selValId);
        if (foundVal?.image) {
          selOptionImages.push(foundVal.image);
        }
      }
    });

    if (lastChangedOptionId) {
      const lastChangedOpt = product.options.find(
        (o) => o.id === lastChangedOptionId
      );
      if (lastChangedOpt) {
        const selValId = selectedOptions[lastChangedOptionId];
        const foundVal = lastChangedOpt.values.find((v) => v.id === selValId);
        if (foundVal?.image) {
          selOptionImages = selOptionImages.filter(
            (img) => img !== foundVal.image
          );
          selOptionImages.unshift(foundVal.image);
        }
      }
    }

    setDisplayImages([...selOptionImages, ...baseImgs]);
  }, [product, selectedOptions, lastChangedOptionId]);

  // ------------------ Option Click Handler -----------------------
  const handleOptionClick = (optionId, valueId) => {
    setLastChangedOptionId(optionId);
    const updated = { ...selectedOptions, [optionId]: valueId };

    product.options.forEach((opt, i) => {
      if (i > product.options.findIndex((o) => o.id === optionId)) {
        updated[opt.id] = null;
      }
    });

    setSelectedOptions(updated);

    const upAvail = { ...availableOptions };
    product.options.forEach((opt, i) => {
      const prevOptId = product.options[i - 1]?.id;
      if (i === 0) {
        upAvail[opt.id] = opt.values;
      } else if (opt.id !== optionId) {
        if (prevOptId && updated[prevOptId]) {
          upAvail[opt.id] = opt.values.filter(
            (v) =>
              v.dependencies.length === 0 ||
              v.dependencies.includes(updated[prevOptId])
          );
        } else {
          upAvail[opt.id] = [];
        }
      }
    });

    setAvailableOptions(upAvail);
    calculateTotalPrice(updated);
  };

  // ------------------ Calculate Price ----------------------------
  const calculateTotalPrice = (opts) => {
    if (!product) return;
    let price = parseFloat(product.price) || 0;
    product.options.forEach((opt) => {
      const selVal = opt.values.find((v) => v.id === opts[opt.id]);
      if (selVal) {
        price += parseFloat(selVal.additional_price);
      }
    });
    setTotalPrice(price);
  };

  // ------------------ Add to Cart -------------------------------
  const addToCart = async () => {
    if (!product || product.quantity <= 0) return;

    // Debug: Log chosen UbiTrays and available UbiTray products
    console.log("chosenUbiTrays: ", chosenUbiTrays);
    console.log("ubiTrayProducts: ", ubiTrayProducts);

    const chosenUbiTraysArray = Object.entries(chosenUbiTrays)
      .filter(([id, qty]) => qty > 0)
      .map(([id, qty]) => {
        const tray = ubiTrayProducts.find(
          (t) => parseInt(t.id, 10) === parseInt(id, 10)
        );
        // Debug: Log each tray found
        console.log(`Tray found for ID ${id}: `, tray);
        return {
          id: parseInt(id, 10),
          quantity: qty,
          name: tray ? tray.name : `UbiTray #${id}`, // Include the name
        };
      });

    // Construct the payload based on the payment method.
    const payload = {
      selectedValues: selectedOptions,
      purchaseMethod: purchaseMethod,
      chosenUbiTrays: chosenUbiTraysArray, // Ensure this includes names
      productId: product.id,
    };

    // Debug: Log the constructed payload
    console.log("Payload to be sent: ", payload);

    // For subscriptions, include subscription plan ID and product slug if available
    if (purchaseMethod === "subscription" && subscriptionPlans.length > 0) {
      payload.subscriptionPlanId = subscriptionPlans[0].id;
      if (product.slug) {
        payload.productSlug = product.slug;
      }
    } else {
      payload.productSlug = product.slug;
    }

    await add_item(payload);
    await get_items();
    await get_total();
    await get_item_total();
    setRedirect(true);

    // Debug: Log the cart state after adding the item
    const cart = JSON.parse(localStorage.getItem("cart") || "[]");
    console.log("Cart state after adding item: ", cart);
  };

  // ------------------ Helper Functions -----------------------
  const optionsExist = product?.options && product.options.length > 0;
  const optionsSelected = Object.keys(selectedOptions).every(
    (k) => selectedOptions[k]
  );
  const traysValid = optionsExist ? totalTrayQuantity === 3 : true;

  // ------------------ Final Price Label --------------------------
  let finalPriceLabel;
  if (purchaseMethod === "subscription" && subscriptionPlans.length) {
    const details = getSubscriptionDetails();
    finalPriceLabel = details?.monthly
      ? `${details.monthly} ₺/ay`
      : `${totalPrice.toLocaleString("tr-TR")} ₺`;
  } else {
    finalPriceLabel = `${totalPrice.toLocaleString("tr-TR")} ₺`;
  }

  // ------------------ Render "Birlikte Gelenler" Section --------------------
  const renderChosenUbiTrays = () => {
    const validEntries = Object.entries(chosenUbiTrays).filter(
      ([, quantity]) => quantity > 0
    );
    if (validEntries.length > 0) {
      return validEntries.map(([trayId, quantity], index, arr) => {
        const tray = ubiTrayProducts?.find(
          (t) => parseInt(t.id, 10) === parseInt(trayId, 10)
        );
        const trayName = tray ? tray.name : `UbiTray #${trayId}`;
        return (
          <Typography
            key={trayId}
            variant="body2"
            style={{ marginLeft: 5, display: "inline" }}
          >
            {trayName}
            {quantity > 1 ? ` (${quantity})` : ""}
            {index < arr.length - 1 ? ", " : ""}
          </Typography>
        );
      });
    }
    return null;
  };

  // ------------------ Responsive Hook --------------------
  const isDesktop = useMediaQuery("(min-width:800px)");

  return (
    <>
      <Grid container spacing={4} style={{ marginTop: 20, padding: 20 }}>
        {/* LEFT side (images) */}
        <Grid item sm={12} md={8}>
          <Grid
            container
            style={{
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 20,
              padding: isDesktop ? 30 : 10,
              backgroundColor: "#FBFBFD",
            }}
          >
            <ImageSlider
              key={displayImages.join("|")}
              product={product}
              images={displayImages}
            />
          </Grid>
        </Grid>

        {/* RIGHT side (options, subscription, etc.) */}
        <Grid item sm={12} md={4}>
          <Typography variant="h3" gutterBottom>
            {product?.name}
          </Typography>

          {/* Rating display */}
          {reviewsLoading ? (
            <Box display="flex" alignItems="center" mb={1}>
              <CircularProgress size={20} />
              <Typography variant="body2" ml={1} color="text.secondary">
                Değerlendirmeler yükleniyor...
              </Typography>
            </Box>
          ) : (
            <Box display="flex" alignItems="center" mb={1}>
              <Rating value={Number(averageRating)} precision={0.5} readOnly />
              <Typography variant="body2" ml={1} color="text.secondary">
                ({totalRatings} değerlendirme)
              </Typography>
            </Box>
          )}

          {/* Stock / shipping info */}
          <Typography>
            {product?.quantity > 0 ? (
              <Grid container direction="row">
                <Typography style={{ color: "green", marginRight: 10 }}>
                  Stokta var,
                </Typography>
                <Typography color="#6A727E">Ücretsiz teslimat</Typography>
                {product.kargo_durumu && (
                  <Grid
                    container
                    flexDirection="row"
                    style={{ alignItems: "center", marginTop: 10 }}
                  >
                    <CircleIcon style={{ marginRight: 10, fontSize: 10 }} />
                    <Typography>{product.kargo_durumu}</Typography>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Typography style={{ color: "red" }}>Stokta Yok</Typography>
            )}
          </Typography>

          <Divider light style={{ margin: "20px 0" }} />

          {/* Options */}
          {product?.options?.map((option, idx) => (
            <div key={option.id} style={{ marginBottom: 20 }}>
              <Typography variant="h6">
                {option.name}
                {option.name === "Renk" && ". Favorinizi seçin."}
              </Typography>

              <Box
                display="flex"
                flexWrap="wrap"
                flexDirection="column"
                gap={2}
                mt={2}
              >
                {availableOptions[option.id]
                  ?.map((val) => {
                    const newChoicePrice =
                      parseFloat(val.additional_price) || 0;
                    const newChoiceSubPrice = getOptionSubscriptionPrice(val);

                    const cumulativePriceBefore = product.options
                      .slice(0, idx)
                      .reduce((acc, opt) => {
                        const selVal = opt.values.find(
                          (v) => v.id === selectedOptions[opt.id]
                        );
                        return (
                          acc +
                          (selVal ? parseFloat(selVal.additional_price) : 0)
                        );
                      }, parseFloat(product.price));

                    const cumulativeSubPriceBefore = product.options
                      .slice(0, idx)
                      .reduce((acc, opt) => {
                        const selVal = opt.values.find(
                          (v) => v.id === selectedOptions[opt.id]
                        );
                        return (
                          acc +
                          (selVal ? getOptionSubscriptionPrice(selVal) : 0)
                        );
                      }, parseFloat(product.price));

                    const rawPrice = cumulativePriceBefore + newChoicePrice;
                    const rawSubscriptionPrice =
                      cumulativeSubPriceBefore + newChoiceSubPrice;
                    const displayPrice = rawPrice.toLocaleString("tr-TR");

                    let subscriptionLabel = "";
                    if (subscriptionPlans.length > 0) {
                      const plan = subscriptionPlans[0];
                      const planBase = parseFloat(plan.monthly_price) || 0;
                      const basePrice = parseFloat(product.price) || 0;
                      const configSubCost = rawSubscriptionPrice - basePrice;
                      const monthlySubCost = planBase + configSubCost;
                      subscriptionLabel = ` veya ${monthlySubCost.toLocaleString(
                        "tr-TR",
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )} ₺/ay`;
                    }

                    return {
                      ...val,
                      rawPrice,
                      displayPrice,
                      subscriptionLabel,
                    };
                  })
                  .sort((a, b) => a.rawPrice - b.rawPrice)
                  .map((val) => {
                    const isSelected = selectedOptions[option.id] === val.id;
                    const outOfStock = val.stock === 0;
                    const waitingForPrev =
                      idx > 0 &&
                      product.options
                        .slice(0, idx)
                        .some((prevOpt) => !selectedOptions[prevOpt.id]);

                    let finalStyle = { ...buttonStyle, paddingTop: 15 };
                    if (isSelected) {
                      finalStyle.borderColor = "#2680EB";
                      finalStyle.color = "#2680EB";
                      finalStyle.borderWidth = 2;
                    }
                    if (outOfStock || waitingForPrev) {
                      finalStyle.opacity = 0.5;
                      finalStyle.pointerEvents = "none";
                    }

                    return (
                      <Button
                        key={val.id}
                        variant="outlined"
                        style={finalStyle}
                        onClick={() =>
                          !waitingForPrev &&
                          handleOptionClick(option.id, val.id)
                        }
                      >
                        {waitingForPrev ? (
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontStyle: "italic",
                            }}
                          >
                            Önce önceki seçenekleri seçiniz
                          </Typography>
                        ) : (
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                          >
                            <Grid container>
                              {option.name === "Renk" && val.color_code && (
                                <Box
                                  sx={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: "50%",
                                    backgroundColor: val.color_code,
                                    marginRight: 2,
                                  }}
                                />
                              )}
                              <Typography variant="body1">
                                {val.value} - {val.displayPrice} ₺
                                {val.subscriptionLabel && (
                                  <span
                                    style={{
                                      fontSize: "0.8rem",
                                      marginLeft: 4,
                                    }}
                                  >
                                    {val.subscriptionLabel}
                                  </span>
                                )}
                              </Typography>
                            </Grid>
                            {val.description && (
                              <Typography
                                variant="body2"
                                component="div"
                                style={{ color: "#6A727E" }}
                                dangerouslySetInnerHTML={{
                                  __html: val.description,
                                }}
                              />
                            )}
                          </Box>
                        )}
                      </Button>
                    );
                  })}
              </Box>
            </div>
          ))}

          {/* UbiTray Button */}
          {product?.options &&
            product.options.length > 0 &&
            ubiTrayProducts?.length > 0 && (
              <>
                <Typography variant="h6" gutterBottom>
                  UbiTray Seçimi
                </Typography>
                <Button
                  variant="outlined"
                  onClick={handleOpenModal}
                  style={{
                    ...buttonStyle,
                    borderColor:
                      Object.values(chosenUbiTrays).reduce((a, b) => a + b, 0) >
                      0
                        ? "#2680EB"
                        : "#E0E0E0",
                    color:
                      Object.values(chosenUbiTrays).reduce((a, b) => a + b, 0) >
                      0
                        ? "#2680EB"
                        : "#000",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1">
                    {Object.values(chosenUbiTrays).reduce(
                      (a, b) => a + b,
                      0
                    ) === 0
                      ? "3 Adet UbiTray Seç"
                      : "Seçilenler: " +
                        Object.entries(chosenUbiTrays)
                          .filter(([id, qty]) => qty > 0)
                          .map(([id, qty]) => {
                            const tray = ubiTrayProducts.find(
                              (t) => String(t.id) === String(id)
                            );
                            return tray
                              ? `${tray.name} (${qty})`
                              : `UbiTray #${id} (${qty})`;
                          })
                          .join(", ")}
                  </Typography>
                </Button>

                <UbiTrayModalSelector
                  open={ubiTrayModalOpen}
                  onClose={handleCloseModal}
                  ubiTrayProducts={ubiTrayProducts}
                  initialSelected={chosenUbiTrays}
                />
                <Divider style={{ margin: "20px 0" }} />
              </>
            )}

          {/* Payment Method */}
          <Box sx={{ mb: 5 }}>
            <Typography variant="h5" gutterBottom>
              Ödeme Yöntemi
            </Typography>
            <Box sx={{ display: "flex", gap: 2, maxWidth: 450 }}>
              <Button
                variant="outlined"
                onClick={() => setPurchaseMethod("one_time")}
                style={{
                  ...buttonStyle,
                  flex: 1,
                  borderColor:
                    purchaseMethod === "one_time" ? "#2680EB" : "#E0E0E0",
                  color: purchaseMethod === "one_time" ? "#2680EB" : "#000",
                  height: 160,
                }}
              >
                <Box>
                  <Typography variant="body1">Peşin Ödeme</Typography>
                  <Typography variant="body2">
                    {totalPrice.toLocaleString("tr-TR")} ₺
                  </Typography>
                </Box>
              </Button>
              <Button
                variant="outlined"
                onClick={() => setPurchaseMethod("subscription")}
                disabled={!subscriptionPlans.length}
                style={{
                  ...buttonStyle,
                  flex: 1,
                  borderColor:
                    purchaseMethod === "subscription" ? "#2680EB" : "#E0E0E0",
                  color: purchaseMethod === "subscription" ? "#2680EB" : "#000",
                  opacity: !subscriptionPlans.length ? 0.5 : 1,
                  pointerEvents: !subscriptionPlans.length ? "none" : "auto",
                }}
              >
                <Box>
                  <Typography variant="body1">Abonelik</Typography>
                  {subscriptionPlans.length ? (
                    <>
                      <Typography variant="body2">
                        {getSubscriptionDetails()?.monthly} ₺/ay
                      </Typography>
                      <Box component="ul" sx={{ pl: 2, mt: 1, mb: 0 }}>
                        {getSubscriptionDetails()?.benefits.map(
                          (benefit, i) => (
                            <Typography
                              key={i}
                              component="li"
                              variant="body2"
                              sx={{ fontSize: "0.75rem", color: "#6A727E" }}
                            >
                              {benefit}
                            </Typography>
                          )
                        )}
                      </Box>
                    </>
                  ) : (
                    <Typography variant="body2">
                      Abonelik seçeneği mevcut değil
                    </Typography>
                  )}
                </Box>
              </Button>
            </Box>
          </Box>

          {/* Final Price */}
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Toplam Fiyat: {finalPriceLabel}
          </Typography>

          {/* Add to Cart */}
          <Button
            variant="contained"
            color="primary"
            disabled={!(optionsSelected && traysValid)}
            onClick={addToCart}
            style={{ textTransform: "none", fontSize: 18 }}
          >
            Sepete Ekle
          </Button>

          <Divider style={{ margin: "20px 0" }} />

          {/* Guarantee Info */}
          <Grid
            container
            alignItems="center"
            style={{
              flexDirection: "row",
              backgroundColor: "#FBFBFD",
              padding: 20,
              borderRadius: 10,
            }}
          >
            <Grid item style={{ marginRight: 10 }}>
              <CheckCircleOutlineIcon style={{ color: "#4CAF50" }} />
            </Grid>
            <Grid item xs>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                100 gün para iade garantisi
              </Typography>
              <Typography variant="body2" style={{ color: "#6A727E" }}>
                Ürünü aldıktan sonra 100 gün boyunca deneyin. Memnun kalmazsanız
                paranız iade.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            style={{
              flexDirection: "row",
              backgroundColor: "#FBFBFD",
              padding: 20,
              borderRadius: 10,
              marginTop: 10,
            }}
          >
            <Grid item style={{ marginRight: 10 }}>
              <WorkspacePremiumIcon style={{ color: "#4CAF50" }} />
            </Grid>
            <Grid item xs>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                UbiTray Garanti
              </Typography>
              <Typography variant="body2" style={{ color: "#6A727E" }}>
                Eğer tohumlar filizlenmezse, ek ücret olmadan yeni UbiTray
                gönderiyoruz.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* "Birlikte Gelenler" Section */}
      {product && product.birlikte_gelenler_ubitray && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 40, padding: 20 }}
        >
          <Typography
            style={{
              marginBottom: 30,
              fontWeight: "bold",
              background:
                "linear-gradient(90deg, #69BFF9 0%, #2BA24C 35%, #B7CDE8 74%, #15922E 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "inline-block",
            }}
            variant="h3"
          >
            Birlikte Gelenler
          </Typography>
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            direction="row"
            style={{
              borderRadius: 10,
              padding: 20,
              paddingTop: 60,
              paddingBottom: 150,
            }}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={chosenImage}
                  alt={product.name}
                  style={{
                    maxWidth: isDesktop ? 300 : 250,
                    maxHeight: isDesktop ? 300 : 250,
                    marginBottom: 20,
                    borderRadius: 10,
                  }}
                />
                <Typography>{product.name}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={UbiTray}
                  alt="UbiTray Görsel"
                  style={{
                    width: isDesktop ? 200 : 250,
                    marginBottom: 20,
                  }}
                />
                <Typography style={{ maxWidth: 300 }}>
                  {Object.keys(chosenUbiTrays).length > 0
                    ? renderChosenUbiTrays()
                    : "Lütfen UbiTray Seçiniz"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={UbiEnergy}
                  alt="UbiEnergy Görsel"
                  style={{
                    width: isDesktop ? 200 : 250,
                    marginBottom: 20,
                    borderRadius: 10,
                  }}
                />
                <Typography>UbiEnergy</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProductDetailCardWithOptions;
