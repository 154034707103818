import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import {
  Rating,
  Box,
  Typography,
  CircularProgress,
  Avatar,
  Grid,
} from "@mui/material";
import ImageSlider from "./ImageSlider";

const ProductReviews = ({
  productSlug,
  reviews = [],
  loading = false,
  error = null,
}) => {
  // State for the slider modal
  const [sliderImages, setSliderImages] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [sliderOpen, setSliderOpen] = useState(false);

  // Compute total ratings and average rating using all reviews (including rating-only submissions).
  const { totalRatings, averageRating } = useMemo(() => {
    if (reviews.length === 0) return { totalRatings: 0, averageRating: 0 };
    const totalRatings = reviews.length;
    const sumRatings = reviews.reduce((acc, review) => acc + review.rating, 0);
    const averageRating = (sumRatings / totalRatings).toFixed(1);
    return { totalRatings, averageRating };
  }, [reviews]);

  // Filter out reviews that are "rating-only" (i.e. reviews without a comment).
  const reviewsWithComment = useMemo(() => {
    return reviews.filter(
      (review) => review.comment && review.comment.trim().length > 0
    );
  }, [reviews]);

  // Opens the slider modal for the given images starting at the selected index.
  const handleOpenSlider = (images, index) => {
    setSliderImages(images.map((img) => img.image_url));
    setSliderIndex(index);
    setSliderOpen(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center" my={4}>
        {error}
      </Typography>
    );
  }

  return (
    <Box my={4}>
      {/* Aggregated Rating Summary */}
      <Box mb={3} textAlign="center">
        <Typography variant="h4" color="textSecondary">
          {averageRating}
        </Typography>
        <Rating
          value={Number(averageRating)}
          precision={0.1}
          readOnly
          size="large"
        />
        <Typography variant="subtitle1" color="textSecondary">
          ({totalRatings} değerlendirme)
        </Typography>
      </Box>

      {/* Render only reviews with comments */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {reviewsWithComment.length === 0 ? (
          <Typography variant="body1">Henüz bu ürün için yorum yok</Typography>
        ) : (
          reviewsWithComment.map((review) => (
            <Box
              key={review.id}
              mb={3}
              p={2}
              border="1px solid #e0e0e0"
              borderRadius="10px"
              maxWidth={800}
              width="90%"
            >
              <Box display="flex" alignItems="center" mt={1}>
                <Avatar>
                  {review.user_email
                    ? review.user_email.charAt(0).toUpperCase()
                    : "U"}
                </Avatar>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ ml: 2, mr: 4 }}
                >
                  {new Date(review.created_at).toLocaleDateString()}
                </Typography>
                <Rating value={review.rating} readOnly size="small" />
              </Box>
              <Typography variant="body2" mt={4}>
                {review.comment}
              </Typography>
              {/* Render thumbnails for review images */}
              {review.images && review.images.length > 0 && (
                <Box display="flex" mt={5}>
                  {review.images.map((img, index) => (
                    <Box key={index} mr={1}>
                      <img
                        src={img.image_url}
                        alt={`Review ${review.id} - ${index + 1}`}
                        style={{
                          maxWidth: "150px",
                          maxHeight: "150px",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenSlider(review.images, index)}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ))
        )}
      </Grid>

      {/* Render the slider modal */}
      {sliderOpen && (
        <ImageSlider
          images={sliderImages}
          initialIndex={sliderIndex}
          open={sliderOpen}
          onClose={() => setSliderOpen(false)}
        />
      )}
    </Box>
  );
};

export default ProductReviews;
