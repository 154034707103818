import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Grid,
  Select,
  MenuItem,
  Alert,
  Chip,
  useMediaQuery,
  useTheme,
  IconButton,
  Modal,
  Paper,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const UbiTrayModalSelector = ({
  open,
  onClose,
  ubiTrayProducts = [],
  initialSelected = {},
  subscription = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedTrayQuantities, setSelectedTrayQuantities] =
    useState(initialSelected);
  const [activeTray, setActiveTray] = useState(null);
  const [detailPopupOpen, setDetailPopupOpen] = useState(false);
  const detailsRef = useRef(null);

  // Calculate total quantity of selected trays
  const totalQuantity = Object.values(selectedTrayQuantities).reduce(
    (acc, qty) => acc + qty,
    0
  );

  useEffect(() => {
    if (ubiTrayProducts && ubiTrayProducts.length > 0 && !activeTray) {
      setActiveTray(ubiTrayProducts[0]);
    }
  }, [ubiTrayProducts, activeTray]);

  const handleQuantityChange = (trayId, value) => {
    const quantity = parseInt(value, 10);
    setSelectedTrayQuantities((prev) => ({ ...prev, [trayId]: quantity }));
  };

  const handleClickTrayName = (tray, isNameClick) => {
    setActiveTray(tray);

    // Only show popup if on mobile and it was a click on the name/image
    if (isMobile && isNameClick) {
      setDetailPopupOpen(true);
    }
  };

  const handleCloseDetailPopup = () => {
    setDetailPopupOpen(false);
  };

  const handleSave = () => {
    // Enforce exactly 3 UbiTrays
    if (totalQuantity !== 3) {
      return;
    }
    // Filter out trays with zero quantity
    const filteredSelection = Object.entries(selectedTrayQuantities)
      .filter(([_, qty]) => qty > 0)
      .reduce((acc, [id, qty]) => ({ ...acc, [id]: qty }), {});
    const finalTotal = Object.values(filteredSelection).reduce(
      (sum, qty) => sum + qty,
      0
    );
    if (finalTotal !== 3) {
      return;
    }
    // Return the selection to the parent
    onClose(filteredSelection, "save");
  };

  const handleCancel = () => {
    onClose(initialSelected, "cancel");
  };

  const getSelectedTrays = () => {
    return Object.entries(selectedTrayQuantities)
      .filter(([_, qty]) => qty > 0)
      .map(([id, qty]) => {
        const tray = ubiTrayProducts.find(
          (t) => t.id.toString() === id.toString()
        );
        return { ...tray, quantity: qty };
      });
  };

  const isOverLimit = totalQuantity > 3;
  const isExactlyThree = totalQuantity === 3;

  // Detail popup content
  const renderTrayDetails = () => {
    if (!activeTray) return null;

    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          {activeTray.name}
        </Typography>
        {activeTray.image && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: 16,
            }}
          >
            <img
              src={activeTray.image}
              alt={activeTray.name}
              style={{
                width: "100%",
                maxWidth: 300,
                maxHeight: 220,
                objectFit: "cover",
              }}
            />
          </div>
        )}
        <Divider sx={{ my: 2 }} />
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Ek Bilgiler
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" fontWeight="bold">
                Hasat Süresi:
              </Typography>
              <Typography variant="body2">
                {activeTray.hasat_zamani || "Bilinmiyor"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" fontWeight="bold">
                Haftalık Hasat Süresi:
              </Typography>
              <Typography variant="body2">
                {activeTray.weeklyHarvest || "Bilinmiyor"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" fontWeight="bold">
                Aylık Üretim:
              </Typography>
              <Typography variant="body2">
                {activeTray.monthlyProduction || "Bilinmiyor"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" fontWeight="bold">
                UbiTray'deki Bitki Sayısı:
              </Typography>
              <Typography variant="body2">
                {activeTray.adet_tohum
                  ? `${activeTray.adet_tohum} Bitki`
                  : "Bilinmiyor"}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" sx={{ mt: 2 }}>
            {activeTray.description || "Bu UbiTray hakkında detay bilgisi yok."}
          </Typography>
        </Box>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={handleCloseDetailPopup}
            fullWidth
          >
            Kapat
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCancel}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
        scroll="paper"
      >
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant={isMobile ? "subtitle1" : "h6"}>
              UbiTray Seçim ({totalQuantity}/3)
              {subscription && " – Her 3 Ayda Bir Teslimat"}
            </Typography>
            {isExactlyThree && (
              <Chip label="Tamamlandı" color="success" size="small" />
            )}
            {isOverLimit && (
              <Chip label="Fazla Seçim" color="error" size="small" />
            )}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2, p: isMobile ? 1 : 2 }}>
            {isExactlyThree ? (
              <Alert severity="success" sx={{ mb: 1 }}>
                Tam olarak 3 UbiTray seçildi. Devam edebilirsiniz.
              </Alert>
            ) : isOverLimit ? (
              <Alert severity="error" sx={{ mb: 1 }}>
                {`Fazla seçim yaptınız! Lütfen toplam 3 adet UbiTray seçiniz. Şu an: ${totalQuantity}`}
              </Alert>
            ) : (
              <Alert severity="warning" sx={{ mb: 1 }}>
                {`Daha fazla seçim yapmalısınız. Lütfen toplamda 3 adet UbiTray seçiniz. Şu an: ${totalQuantity}`}
              </Alert>
            )}

            {totalQuantity > 0 && (
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Typography variant="body2">Seçilen UbiTray'ler:</Typography>
                {getSelectedTrays().map((tray) => (
                  <Chip
                    key={tray.id}
                    label={`${tray.name} (${tray.quantity})`}
                    color={isOverLimit ? "error" : "primary"}
                    size="small"
                    onDelete={() => handleQuantityChange(tray.id, 0)}
                  />
                ))}
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              height: isMobile ? "auto" : 550,
              overflow: isMobile ? "visible" : "hidden",
            }}
          >
            {/* UbiTray list with full width on mobile */}
            <Box
              sx={{
                width: "100%",
                maxWidth: isMobile ? "100%" : 250,
                minWidth: isMobile ? "100%" : 250,
                overflowY: isMobile ? "visible" : "auto",
                borderRight: isMobile ? 0 : 1,
                borderColor: "divider",
                mr: isMobile ? 0 : 2,
                mb: isMobile ? 0 : 0,
                pb: isMobile ? 0 : 0,
              }}
            >
              <Typography variant="body1" gutterBottom>
                Toplam Seçim: {totalQuantity} adet
                {isExactlyThree && (
                  <Chip
                    label="Tamamlandı"
                    color="success"
                    size="small"
                    sx={{ ml: 1 }}
                  />
                )}
              </Typography>
              <List dense>
                {ubiTrayProducts.map((tray) => {
                  const quantity = selectedTrayQuantities[tray.id] || 0;
                  const isOutOfStock = tray.quantity <= 0;
                  return (
                    <ListItem
                      key={tray.id}
                      button
                      onClick={() => handleClickTrayName(tray, true)}
                      selected={activeTray && activeTray.id === tray.id}
                      sx={isOutOfStock ? { opacity: 0.6 } : {}}
                    >
                      <ListItemText
                        primary={
                          <>
                            {tray.name}
                            {isOutOfStock && (
                              <Chip
                                label="Stokta Yok"
                                color="error"
                                size="small"
                                sx={{ ml: 1, height: 20, fontSize: "0.7rem" }}
                              />
                            )}
                          </>
                        }
                        secondary={
                          tray.adet_tohum
                            ? `${tray.adet_tohum} Bitki`
                            : "Bilinmiyor"
                        }
                      />
                      {isMobile && (
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveTray(tray);
                            setDetailPopupOpen(true);
                          }}
                        >
                          <InfoOutlinedIcon />
                        </IconButton>
                      )}
                      <Box
                        sx={{ width: 80 }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Select
                          value={quantity}
                          onChange={(e) =>
                            handleQuantityChange(tray.id, e.target.value)
                          }
                          size="small"
                          sx={{ width: "100%" }}
                          disabled={isOutOfStock}
                        >
                          {[0, 1, 2, 3].map((num) => (
                            <MenuItem key={num} value={num}>
                              {num}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </ListItem>
                  );
                })}
              </List>

              {/* Mobile info text */}
              {isMobile && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Detayları görmek için UbiTray'e veya bilgi ikonuna tıklayın
                </Typography>
              )}
            </Box>

            {/* UbiTray details ONLY for desktop */}
            {!isMobile && (
              <Box
                ref={detailsRef}
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                  maxHeight: "550px",
                }}
              >
                {activeTray ? (
                  <>
                    <Typography variant="h6" gutterBottom>
                      {activeTray.name}
                    </Typography>
                    {activeTray.image && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <img
                          src={activeTray.image}
                          alt={activeTray.name}
                          style={{
                            width: "100%",
                            maxWidth: 300,
                            maxHeight: 220,
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    )}
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Ek Bilgiler
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body2" fontWeight="bold">
                            Hasat Süresi:
                          </Typography>
                          <Typography variant="body2">
                            {activeTray.hasat_zamani || "Bilinmiyor"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" fontWeight="bold">
                            Haftalık Hasat Süresi:
                          </Typography>
                          <Typography variant="body2">
                            {activeTray.weeklyHarvest || "Bilinmiyor"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" fontWeight="bold">
                            Aylık Üretim:
                          </Typography>
                          <Typography variant="body2">
                            {activeTray.monthlyProduction || "Bilinmiyor"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" fontWeight="bold">
                            UbiTray'deki Bitki Sayısı:
                          </Typography>
                          <Typography variant="body2">
                            {activeTray.adet_tohum
                              ? `${activeTray.adet_tohum} Bitki`
                              : "Bilinmiyor"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography variant="body2" sx={{ mt: 2 }}>
                        {activeTray.description ||
                          "Bu UbiTray hakkında detay bilgisi yok."}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Listeden bir UbiTray seçiniz.
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Vazgeç</Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!isExactlyThree}
            color={isExactlyThree ? "success" : "primary"}
          >
            {subscription ? "Aboneliğe Ekle" : "Kaydet"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Detail Popup for Mobile View Only */}
      {isMobile && (
        <Modal
          open={detailPopupOpen}
          onClose={handleCloseDetailPopup}
          aria-labelledby="ubitray-detail-popup"
          aria-describedby="ubitray-detail-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper
            sx={{
              width: "90%",
              maxWidth: 500,
              maxHeight: "90vh",
              overflow: "auto",
              position: "relative",
              borderRadius: 2,
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                zIndex: 10,
              }}
              onClick={handleCloseDetailPopup}
            >
              <CloseIcon />
            </IconButton>
            {renderTrayDetails()}
          </Paper>
        </Modal>
      )}
    </>
  );
};

export default UbiTrayModalSelector;
