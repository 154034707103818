import React, { Fragment } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import ProductDetailVideos from "./ProductDetailVideos";
import ProductDetailAdvancedDetail from "./ProductDetailAdvanedDetail";

const ProductDetailCard = ({ product }) => {
  // Determine if we are on a desktop based on a 800px breakpoint.
  const isDesktop = useMediaQuery("(min-width:800px)");
  const containerMaxWidth = isDesktop ? 1800 : "100%";

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 20, padding: 20, maxWidth: containerMaxWidth }}
      >
        {product && product.category === 1 && (
          <Fragment>
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <ProductDetailVideos product={product} />
            </Grid>
            <ProductDetailAdvancedDetail product={product} />
          </Fragment>
        )}
      </Grid>
    </div>
  );
};

export default ProductDetailCard;
