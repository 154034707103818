import React, { useState, useEffect } from "react";
import {
  Card,
  Stack,
  Typography,
  Chip,
  Divider,
  Button,
  Collapse,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import dayjs from "dayjs";
import InventoryIcon from "@mui/icons-material/Inventory";
import SubscriptionUbiTrayEditor from "./SubscriptionUbiTrayEditor";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import { get_ubitray } from "../actions/products";
import { updateSubscriptionUbiTrays } from "../actions/subscriptions";

const SubscriptionCard = ({
  subscription,
  handleCancelSubscription,
  setAlert,
  get_ubitray,
  ubiTrayProducts,
  updateSubscriptionUbiTrays,
}) => {
  // Local state to toggle display of previous orders
  const [showOrders, setShowOrders] = useState(false);
  const [showUbiTrayEditor, setShowUbiTrayEditor] = useState(false);

  // Debug logging
  useEffect(() => {
    console.log("----------- Subscription Debug Info ------------");
    console.log("DEBUG: Subscription ID:", subscription.id);
    console.log("DEBUG: Subscription plan:", subscription);
    if (subscription.plan) {
      console.log("DEBUG: Plan details:", {
        id: subscription.plan.id,
        name: subscription.plan.name,
        ask_choice: subscription.plan.ask_choice,
        product: subscription.plan.product?.name,
      });
    } else {
      console.log("DEBUG: No plan found in subscription!");
    }
    console.log("DEBUG: UbiTrays data:", subscription.chosen_ubi_trays);
    console.log("DEBUG: Is gift subscription?", isGiftSubscription());
    console.log(
      "DEBUG: Is UbiTray selection enabled?",
      isUbiTraySelectionEnabled()
    );
    console.log("DEBUG: Subscription status:", subscription.status);
    console.log("----------- End Debug Info ------------");
  }, [subscription]);

  // Helper to identify gift subscriptions more reliably
  const isGiftSubscription = () => {
    // Check for parent subscription ID (most reliable indicator)
    if (subscription.parent_subscription_id) {
      return true;
    }

    // Check for zero price
    if (
      subscription.monthly_total_price === 0 ||
      parseFloat(subscription.monthly_total_price) === 0 ||
      subscription.monthly_total_price === "0" ||
      subscription.monthly_total_price === "0.00"
    ) {
      return true;
    }

    return false;
  };

  // Helper to determine if UbiTray selection is applicable for this subscription
  const isUbiTraySelectionEnabled = () => {
    // For now, we're only checking if the plan has ask_choice=true and is active
    // We removed the gift subscription check to allow gift subscriptions to select UbiTrays
    return (
      subscription.plan &&
      subscription.plan.ask_choice === true &&
      subscription.status === "active"
      // Removed the "!isGiftSubscription()" check so gift subscriptions can select UbiTrays
    );
  };

  // Constructs the display name based on the following order:
  // 1. Base product name (from subscription.plan.product.name)
  // 2. Model (option id 1) – appended only if not "Base"
  // 3. Color (option id 2) – always appended if exists
  // 4. Iklimlendirme (option id 3) – append "iklimlendirmeli" if value is "Var"
  const getDisplayName = () => {
    let displayName = "";
    // Base product name
    if (
      subscription.plan &&
      subscription.plan.product &&
      subscription.plan.product.name
    ) {
      displayName = subscription.plan.product.name;
    } else if (subscription.plan && subscription.plan.name) {
      displayName = subscription.plan.name;
    }

    // Find option values by option ID
    const modelOv = subscription.option_values.find(
      (ov) => parseInt(ov.option, 10) === 1
    );
    const colorOv = subscription.option_values.find(
      (ov) => parseInt(ov.option, 10) === 2
    );
    const iklimOv = subscription.option_values.find(
      (ov) => parseInt(ov.option, 10) === 3
    );

    // Append model if exists and not "Base"
    if (modelOv && modelOv.value && modelOv.value.toLowerCase() !== "base") {
      displayName += " " + modelOv.value;
    }
    // Append color if exists
    if (colorOv && colorOv.value) {
      displayName += " " + colorOv.value;
    }
    // Append iklimlendirmeli if iklimlendirme value is "Var"
    if (iklimOv && iklimOv.value && iklimOv.value.toLowerCase() === "var") {
      displayName += " iklimlendirmeli";
    }
    return displayName;
  };

  // Determine whether the subscription can be canceled.
  const canCancel = () => {
    // Gift subscriptions can't be directly canceled
    if (isGiftSubscription()) {
      return false;
    }

    // Only active subscriptions can be canceled.
    if (subscription.status !== "active") {
      return false;
    }
    // Ensure the plan and minimum_months exist.
    if (!subscription.plan || !subscription.plan.minimum_months) {
      return false;
    }
    const now = dayjs();
    const monthsElapsed = now.diff(dayjs(subscription.start_date), "month");
    // Cancellation is allowed only if elapsed months are greater than or equal to minimum_months.
    return monthsElapsed >= subscription.plan.minimum_months;
  };

  // Format dates and price.
  const startDate = dayjs(subscription.start_date);

  // Debug this calculation
  console.log("DEBUG UI: Calculating finishDateText");
  console.log("DEBUG UI: Status is", subscription.status);
  console.log("DEBUG UI: Has parent?", !!subscription.parent_subscription_id);
  console.log("DEBUG UI: Monthly price", subscription.monthly_total_price);
  console.log("DEBUG UI: End date", subscription.end_date);
  console.log("DEBUG UI: Is gift subscription?", isGiftSubscription());

  const finishDateText =
    subscription.status === "canceled"
      ? subscription.end_date
        ? dayjs(subscription.end_date).format("LL")
        : "İptal Edildi"
      : isGiftSubscription()
      ? "Ana aboneliğe bağlı"
      : subscription.plan && subscription.plan.maximum_months
      ? startDate.add(subscription.plan.maximum_months, "month").format("LL")
      : "Süresiz";

  console.log("DEBUG UI: Final finishDateText:", finishDateText);
  const quantity = subscription.quantity + " adet";
  const formattedMonthlyPrice = subscription.monthly_total_price
    ? parseFloat(subscription.monthly_total_price).toLocaleString("tr-TR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : subscription.plan
    ? Math.round(subscription.plan.monthly_price)
    : "Belirtilmemiş";

  // Function to render UbiTray information
  const renderUbiTrays = () => {
    if (
      !subscription.chosen_ubi_trays ||
      Object.keys(subscription.chosen_ubi_trays).length === 0
    ) {
      return (
        <Typography
          variant="body2"
          sx={{ fontStyle: "italic", color: "text.secondary" }}
        >
          Seçili UbiTray bulunmamaktadır.
        </Typography>
      );
    }

    // Function to get UbiTray name from ID
    const getUbiTrayName = (trayId) => {
      if (!ubiTrayProducts || ubiTrayProducts.length === 0) {
        return `UbiTray #${trayId}`;
      }

      const tray = ubiTrayProducts.find(
        (tray) => tray.id.toString() === trayId.toString()
      );
      return tray ? tray.name : `UbiTray #${trayId}`;
    };

    return (
      <List dense>
        {Object.entries(subscription.chosen_ubi_trays).map(
          ([trayId, quantity]) => (
            <ListItem key={trayId}>
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText
                primary={getUbiTrayName(trayId)}
                secondary={`Miktar: ${quantity}`}
              />
            </ListItem>
          )
        )}
      </List>
    );
  };

  // Handle UbiTray edit modal
  const handleEditUbiTrays = () => {
    setShowUbiTrayEditor(true);
  };

  const handleCloseUbiTrayEditor = (success) => {
    setShowUbiTrayEditor(false);
    if (success) {
      setAlert("UbiTray seçimleriniz güncellendi", "success");
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mt: 2,
        p: 3,
        borderRadius: 5,
        boxShadow: "none",
        borderColor: "#e0e0e0",
      }}
    >
      {/* Subscription Header with Display Name */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="subtitle1">{getDisplayName()}</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Chip
            label={subscription.status === "active" ? "Aktif" : "Kapalı"}
            color={subscription.status === "active" ? "success" : "default"}
          />
          {subscription.quantity > 1 && <Chip label={quantity} />}
          {subscription.monthly_total_price === 0 && (
            <Chip label="Hediye" color="info" sx={{ fontWeight: "bold" }} />
          )}
        </Stack>
      </Stack>

      {/* Subscription Dates and Duration */}
      <Typography variant="body2" sx={{ mt: 1 }}>
        Başlangıç Tarihi: {startDate.format("LL")}
      </Typography>
      <Typography variant="body2">Bitiş Tarihi: {finishDateText}</Typography>

      <Divider sx={{ my: 2 }} />

      {/* UbiTray Information - Only show if there are UbiTrays AND the plan has ask_choice=true */}
      {subscription.plan &&
        subscription.plan.ask_choice === true &&
        subscription.chosen_ubi_trays &&
        Object.keys(subscription.chosen_ubi_trays).length > 0 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Seçili UbiTray'ler
              </Typography>
              {isUbiTraySelectionEnabled() && (
                <Button
                  startIcon={<EditIcon />}
                  size="small"
                  onClick={handleEditUbiTrays}
                  sx={{ textTransform: "none" }}
                >
                  Düzenle
                </Button>
              )}
            </Box>
            {renderUbiTrays()}
            <Divider sx={{ my: 2 }} />
          </>
        )}

      {/* If there are no UbiTrays selected but the subscription is active and plan has ask_choice=true */}
      {(!subscription.chosen_ubi_trays ||
        Object.keys(subscription.chosen_ubi_trays).length === 0) &&
        isUbiTraySelectionEnabled() && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                UbiTray Seçimi
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ mb: 2, color: "text.secondary" }}>
              Henüz bir UbiTray seçimi yapmadınız. Her teslimat döneminde
              alacağınız UbiTray'leri seçmek için düzenleyin.
            </Typography>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={handleEditUbiTrays}
              sx={{ mb: 2, textTransform: "none" }}
            >
              UbiTray Seç
            </Button>
            <Divider sx={{ my: 2 }} />
          </>
        )}

      {/* Subscription Plan Info & Billing Dates */}
      <Typography variant="body2">
        Sonraki Fatura Tarihi:{" "}
        {(() => {
          console.log("DEBUG UI: Next billing date rendering");
          console.log(
            "DEBUG UI: monthly_total_price =",
            subscription.monthly_total_price
          );
          console.log(
            "DEBUG UI: next_billing_date =",
            subscription.next_billing_date
          );
          console.log("DEBUG UI: Is gift subscription?", isGiftSubscription());

          if (isGiftSubscription()) {
            console.log(
              "DEBUG UI: Showing 'Ücretlendirilmeyecek' for gift subscription"
            );
            return "Ücretlendirilmeyecek";
          } else if (subscription.next_billing_date) {
            console.log(
              "DEBUG UI: Showing formatted date:",
              dayjs(subscription.next_billing_date).format("LL")
            );
            return dayjs(subscription.next_billing_date).format("LL");
          } else {
            console.log("DEBUG UI: Showing 'Bilinmiyor'");
            return "Bilinmiyor";
          }
        })()}
      </Typography>

      {isGiftSubscription() ? (
        <Box sx={{ mt: 1 }}>
          <Typography variant="body1" color="info.main" fontWeight="bold">
            Ücretsiz Hediye Abonelik
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            Bu abonelik ücretsiz bir hediyedir ve ana aboneliğiniz süresince
            geçerlidir.
            {subscription.parent_subscription_id
              ? " Ana aboneliğiniz iptal edildiğinde bu hediye abonelik de otomatik olarak sonlanacaktır."
              : ""}
          </Typography>
        </Box>
      ) : (
        <Typography variant="body1">{formattedMonthlyPrice} ₺ / Ay</Typography>
      )}

      {/* Action Buttons */}
      <Stack
        direction="row"
        spacing={1}
        sx={{ mt: 2 }}
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={() => handleCancelSubscription(subscription.id)}
          disabled={!canCancel() || isGiftSubscription()}
          title={
            isGiftSubscription()
              ? "Bu ücretsiz hediye abonelik, ana aboneliğinizle birlikte otomatik olarak sonlanacaktır."
              : !canCancel()
              ? "Minimum abonelik süresi dolmadan iptal edilemez."
              : ""
          }
          sx={{ textTransform: "none" }}
        >
          İptal Et
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setShowOrders(!showOrders)}
          sx={{ textTransform: "none" }}
        >
          {showOrders ? "Siparişleri Gizle" : "Siparişleri Göster"}
        </Button>
      </Stack>

      {/* Collapse to Show Previous Orders */}
      <Collapse in={showOrders}>
        <Divider sx={{ my: 2 }} />
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: "bold" }}>
          Geçmiş Siparişler
        </Typography>
        {subscription.previous_orders &&
        subscription.previous_orders.length > 0 ? (
          subscription.previous_orders.map((order) => (
            <Card
              key={order.transaction_id}
              variant="outlined"
              sx={{ mb: 2, p: 2, borderRadius: 2 }}
            >
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                    Sipariş Kodu: {order.transaction_id}
                  </Typography>
                  <Chip
                    label={
                      order.status === "delivered"
                        ? "Teslim Edildi"
                        : order.status === "shipped"
                        ? "Kargoda"
                        : order.status === "processed"
                        ? "Hazırlanıyor"
                        : order.status === "cancelled"
                        ? "İptal Edildi"
                        : "İşleme Alındı"
                    }
                    color={
                      order.status === "delivered"
                        ? "success"
                        : order.status === "shipped"
                        ? "info"
                        : order.status === "processed"
                        ? "primary"
                        : order.status === "cancelled"
                        ? "error"
                        : "default"
                    }
                    size="small"
                  />
                </Stack>
                <Typography variant="body2">
                  Tutar: <strong>{order.amount} TL</strong>
                </Typography>
                <Typography variant="body2">
                  Kargo Ücreti: <strong>{order.shipping_price} TL</strong>
                </Typography>
                <Typography variant="body2">
                  Tarih:{" "}
                  <strong>{dayjs(order.date_issued).format("LL")}</strong>
                </Typography>
              </Stack>
            </Card>
          ))
        ) : (
          <Typography
            variant="body2"
            sx={{ fontStyle: "italic", color: "text.secondary" }}
          >
            Bu abonelik için henüz bir sipariş bulunmamaktadır.
          </Typography>
        )}
      </Collapse>

      {/* UbiTray Editor Modal */}
      <SubscriptionUbiTrayEditor
        open={showUbiTrayEditor}
        onClose={handleCloseUbiTrayEditor}
        subscription={subscription}
        ubiTrayProducts={ubiTrayProducts}
        updateSubscriptionUbiTrays={updateSubscriptionUbiTrays}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  ubiTrayProducts: state.products.ubiTrayProducts,
});

export default connect(mapStateToProps, {
  get_ubitray,
  updateSubscriptionUbiTrays,
})(SubscriptionCard);
