import React, { useEffect } from "react";
import { connect } from "react-redux";
import { get_order_detail } from "../actions/orders";
import { get_ubitray } from "../actions/products";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import * as locales from "dayjs/locale/tr";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import {
  Card,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Button,
} from "@mui/material";

// Icon imports
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

dayjs.extend(localizedFormat);
dayjs.locale("tr");

const OrderItemDetail = ({
  match,
  get_order_detail,
  order,
  status,
  get_ubitray,
  ubiTrayProducts,
}) => {
  useEffect(() => {
    // Debug the match params
    console.log("DEBUG: match.params:", match.params);

    const transactionId =
      match.params.transactionId || match.params.transaction_id;
    console.log("DEBUG: Using transactionId:", transactionId);

    if (transactionId) {
      get_order_detail(transactionId);
    } else {
      console.error("DEBUG: No transaction ID found in URL parameters");
    }

    // Load UbiTray products for reference
    get_ubitray();
  }, [match.params, get_order_detail, get_ubitray]);

  useEffect(() => {
    // Debug: Log order data when it changes
    if (order) {
      console.log("DEBUG: Order data received:", order);
      console.log(
        "DEBUG: Order has chosen_ubi_trays?",
        !!order.chosen_ubi_trays
      );

      if (order.order_items) {
        console.log("DEBUG: Order items:", order.order_items);
        order.order_items.forEach((item, index) => {
          console.log(
            `DEBUG: Order item ${index} has chosen_ubi_trays?`,
            !!item.chosen_ubi_trays
          );
          if (item.chosen_ubi_trays) {
            console.log(
              `DEBUG: Order item ${index} chosen_ubi_trays:`,
              item.chosen_ubi_trays
            );
          }
        });
      }
    }
  }, [order]);

  const showStatus = (status) => {
    if (!status) {
      return (
        <Typography style={{ color: "gray" }}>Durum bilgisi yok</Typography>
      );
    }

    if (status === "not_processed") {
      return <Typography style={{ color: "orange" }}>Onay bekliyor</Typography>;
    } else if (status === "processed") {
      return <Typography style={{ color: "#4DA2E6" }}>Onaylandı</Typography>;
    } else if (status === "shipped") {
      return <Typography style={{ color: "#F2B34B" }}>Kargoda</Typography>;
    } else if (status === "delivered") {
      return <Typography style={{ color: "green" }}>Gönderildi</Typography>;
    } else if (status === "cancelled") {
      return <Typography style={{ color: "red" }}>İptal edildi</Typography>;
    }

    return <Typography style={{ color: "gray" }}>{status}</Typography>;
  };
  let width = window.innerWidth;

  // Add section to display UbiTrays in order details, but only for relevant subscription types
  const renderUbiTrays = (order) => {
    // Check if order exists
    if (!order) {
      console.log("DEBUG: renderUbiTrays - order is null or undefined");
      return null;
    }

    console.log("DEBUG: renderUbiTrays - order:", order);
    console.log(
      "DEBUG: renderUbiTrays - subscription_plan:",
      order.subscription_plan
    );
    console.log(
      "DEBUG: renderUbiTrays - chosen_ubi_trays:",
      order.chosen_ubi_trays
    );

    // Only show for subscription plans with ask_choice=true
    if (!order.subscription_plan || !order.subscription_plan.ask_choice) {
      console.log(
        "DEBUG: renderUbiTrays - No subscription_plan or ask_choice is false"
      );
      return null;
    }

    if (
      !order.chosen_ubi_trays ||
      (Array.isArray(order.chosen_ubi_trays) &&
        order.chosen_ubi_trays.length === 0) ||
      (!Array.isArray(order.chosen_ubi_trays) &&
        Object.keys(order.chosen_ubi_trays).length === 0)
    ) {
      console.log("DEBUG: renderUbiTrays - No chosen_ubi_trays data");
      return null;
    }

    // Format the UbiTray data for display
    let formattedTrays = [];
    if (Array.isArray(order.chosen_ubi_trays)) {
      // Handle array format
      const trayCount = {};
      order.chosen_ubi_trays.forEach((tray) => {
        const id = tray.id || tray;
        trayCount[id] = (trayCount[id] || 0) + 1;
      });
      formattedTrays = Object.entries(trayCount).map(([id, qty]) => ({
        id,
        quantity: qty,
      }));
    } else {
      // Handle object format
      formattedTrays = Object.entries(order.chosen_ubi_trays)
        .filter(([_, qty]) => qty > 0)
        .map(([id, qty]) => ({ id, quantity: qty }));
    }

    if (formattedTrays.length === 0) {
      return null;
    }

    // Function to get UbiTray name from ID
    const getUbiTrayName = (trayId) => {
      if (!ubiTrayProducts || ubiTrayProducts.length === 0) {
        return `UbiTray #${trayId}`;
      }

      const tray = ubiTrayProducts.find(
        (tray) => tray.id.toString() === trayId.toString()
      );
      return tray ? tray.name : `UbiTray #${trayId}`;
    };

    return (
      <>
        <Typography variant="h6" style={{ marginTop: 30 }}>
          UbiTray Seçimleri
        </Typography>
        <List style={{ padding: 0 }}>
          {formattedTrays.map((tray) => (
            <ListItem key={tray.id}>
              <ListItemText
                primary={getUbiTrayName(tray.id)}
                secondary={`Miktar: ${tray.quantity}`}
              />
            </ListItem>
          ))}
        </List>
      </>
    );
  };

  if (width > 800) {
    return (
      <Grid>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Sipariş detay sayfası" />
          <title>Sipariş Detay - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/order-detail" />
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: "#F3F6FB" }}
        >
          <Paper
            item
            variant="outlined"
            style={{ marginTop: 50, borderRadius: 20, width: 500, padding: 30 }}
          >
            <Grid container>
              <LightbulbIcon style={{ color: "#EEAE3D", marginRight: 20 }} />
              {order && order !== null && order !== undefined && (
                <Typography variant="subtitle1" style={{ color: "grey" }}>
                  Sipariş kodu: <strong>{order.transaction_id}</strong> olan
                  sipariş için detay
                </Typography>
              )}
            </Grid>
          </Paper>

          <Card
            variant="outlined"
            style={{
              borderRadius: 20,
              width: 500,
              padding: 30,
              paddingLeft: 40,
              margin: 10,
              marginBottom: 50,
              marginTop: 30,
            }}
          >
            <Button
              variant="outlined"
              href="/dashboard"
              startIcon={<ArrowBackIosNewIcon />}
              style={{ textTransform: "none" }}
            >
              Panele Dön
            </Button>
            <div>
              {order && order !== null && order !== undefined && (
                <div>
                  <Typography variant="h6" style={{ marginTop: 50 }}>
                    Sipariş Durumu
                  </Typography>
                  <Typography style={{ marginTop: 15 }}>
                    {showStatus(order.status)}
                  </Typography>
                  <Typography variant="h6" style={{ marginTop: 30 }}>
                    Sipariş Bilgileri
                  </Typography>
                  <List className="list-group mb-3">
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Sipariş Kodu:</strong>{" "}
                        {order && order.transaction_id}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Toplam Tutar:</strong>{" "}
                        {order && order.amount
                          ? order.amount.toLocaleString()
                          : "0"}{" "}
                        TL
                      </ListItemText>
                    </ListItem>
                    {order && order.subscription && (
                      <ListItem>
                        <ListItemText style={{ maxWidth: 500 }}>
                          <strong>Abonelik:</strong> {order.subscription.name}
                        </ListItemText>
                      </ListItem>
                    )}
                    {order &&
                      order.gift_subscriptions &&
                      order.gift_subscriptions.length > 0 && (
                        <ListItem>
                          <ListItemText style={{ maxWidth: 500 }}>
                            <strong>Hediye Abonelik:</strong>{" "}
                            {order.gift_subscriptions.map((sub, index) => {
                              // Instead of showing the generic subscription name, display "UbiTray"
                              const displayName = "UbiTray Aboneliği";

                              // Get a cleaner parent name if available
                              let parentName = "";
                              if (sub.parent_subscription_name) {
                                const fullParentName =
                                  sub.parent_subscription_name;
                                parentName = fullParentName.includes("for ")
                                  ? fullParentName.split("for ")[0].trim()
                                  : fullParentName;
                              }

                              return (
                                <React.Fragment
                                  key={sub.id || `gift-sub-${index}`}
                                >
                                  {index > 0 && ", "}
                                  <span>
                                    {displayName}
                                    {parentName && (
                                      <span
                                        style={{
                                          color: "#666",
                                          fontStyle: "italic",
                                          fontSize: "0.9em",
                                        }}
                                      ></span>
                                    )}
                                  </span>
                                </React.Fragment>
                              );
                            })}
                          </ListItemText>
                        </ListItem>
                      )}
                  </List>
                  <Typography variant="h6" style={{ marginTop: 30 }}>
                    Adres Bilgileri
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Adres:</strong> {order && order.address_line_1}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Şehir:</strong> {order && order.city}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>İlçe:</strong> {order && order.district}
                      </ListItemText>
                    </ListItem>
                  </List>
                  {renderUbiTrays(order)}
                  <Typography variant="h6" style={{ marginTop: 30 }}>
                    Ek Bilgiler
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Kargo Ücreti:</strong>{" "}
                        {order && order.shipping_price
                          ? order.shipping_price.toLocaleString()
                          : "0"}{" "}
                        TL
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Teslimat Süresi:</strong>{" "}
                        {order && order.shipping_time}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Telefon:</strong>{" "}
                        {order && order.telephone_number}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Sipariş Tarihi:</strong>{" "}
                        {order && order.date_issued
                          ? dayjs(order.date_issued).format("LLL")
                          : "-"}
                      </ListItemText>
                    </ListItem>
                  </List>
                  <Typography
                    variant="h6"
                    style={{ marginTop: 30, marginBottom: 20 }}
                  >
                    Sipariş Öğeleri
                  </Typography>
                  {order &&
                    order.order_items &&
                    order.order_items.map((order_item, index) => (
                      <div key={index}>
                        <Divider style={{ marginBottom: 20 }} />
                        <Typography>
                          {order_item.name.split(" - ")[0]}
                        </Typography>
                        <List>
                          <ListItem>
                            <ListItemText style={{ maxWidth: 500 }}>
                              <strong>Tutar:</strong>{" "}
                              {order_item.price.toLocaleString()} TL
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText style={{ maxWidth: 500 }}>
                              <strong>Adet:</strong> {order_item.count}
                            </ListItemText>
                          </ListItem>
                          {order_item.selected_options && (
                            <ListItem>
                              <ListItemText style={{ maxWidth: 500 }}>
                                <strong>Seçilen Opsiyonlar:</strong>
                                <div style={{ marginTop: 5 }}>
                                  {typeof order_item.selected_options ===
                                  "string"
                                    ? order_item.selected_options
                                        .split(",")
                                        .map((option, i) => {
                                          const parts = option
                                            .trim()
                                            .split(":");
                                          if (parts.length === 2) {
                                            return (
                                              <div key={i}>
                                                <strong>
                                                  {parts[0].trim()}:
                                                </strong>{" "}
                                                {parts[1].trim()}
                                              </div>
                                            );
                                          }
                                          return (
                                            <div key={i}>{option.trim()}</div>
                                          );
                                        })
                                    : typeof order_item.selected_options ===
                                      "object"
                                    ? Object.entries(
                                        order_item.selected_options
                                      ).map(([key, value], i) => {
                                        // Try to make the option key more readable
                                        let optionName = key;
                                        // Common option names to translate from IDs
                                        const optionNameMap = {
                                          1: "Renk",
                                          2: "Model",
                                          3: "İklimlendirme",
                                          4: "Ek Özellik",
                                          5: "Paket",
                                          6: "Boyut",
                                          // Add more mappings as needed
                                        };

                                        if (optionNameMap[key]) {
                                          optionName = optionNameMap[key];
                                        }

                                        // Try to make the value more readable
                                        let displayValue = value;

                                        // Common value mappings based on option type
                                        const valueMap = {
                                          // İklimlendirme values
                                          3: {
                                            5: "Var",
                                            6: "Yok",
                                          },
                                          // Model values
                                          2: {
                                            3: "Base",
                                            4: "Pro",
                                          },
                                          // Add more mappings as needed
                                        };

                                        if (
                                          valueMap[key] &&
                                          valueMap[key][value]
                                        ) {
                                          displayValue = valueMap[key][value];
                                        }

                                        return (
                                          <div key={i}>
                                            <strong>{optionName}:</strong>{" "}
                                            {displayValue}
                                          </div>
                                        );
                                      })
                                    : null}
                                </div>
                              </ListItemText>
                            </ListItem>
                          )}

                          {/* Display chosen UbiTrays if available */}
                          {order_item.chosen_ubi_trays &&
                            Object.keys(order_item.chosen_ubi_trays).length >
                              0 && (
                              <ListItem>
                                <ListItemText style={{ maxWidth: 500 }}>
                                  {console.log(
                                    "DEBUG: Rendering chosen UbiTrays for order item:",
                                    order_item.name
                                  )}
                                  {console.log(
                                    "DEBUG: UbiTrays data:",
                                    order_item.chosen_ubi_trays
                                  )}
                                  <strong>Seçilen UbiTrayler:</strong>
                                  <div style={{ marginTop: 5 }}>
                                    {Object.entries(order_item.chosen_ubi_trays)
                                      .filter(([_, qty]) => qty > 0)
                                      .map(([trayId, quantity], i) => (
                                        <div key={i}>
                                          <strong>UbiTray #{trayId}:</strong>{" "}
                                          {quantity} adet
                                        </div>
                                      ))}
                                  </div>
                                </ListItemText>
                              </ListItem>
                            )}

                          <Divider />
                        </List>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Sipariş detay sayfası" />
          <title>Sipariş Detay - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/order-detail" />
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: "#F3F6FB" }}
        >
          <Paper
            item
            variant="outlined"
            style={{ margin: 10, marginTop: 50, borderRadius: 20, padding: 30 }}
          >
            <Grid container direction="row">
              <LightbulbIcon style={{ color: "#EEAE3D", marginRight: 20 }} />
              {order && order !== null && order !== undefined && (
                <Typography variant="subtitle1" style={{ color: "grey" }}>
                  Sipariş kodu: <strong>{order.transaction_id}</strong> <br />{" "}
                  olan sipariş için detay
                </Typography>
              )}
              <Button
                variant="outlined"
                href="/dashboard"
                startIcon={<ArrowBackIosNewIcon />}
                style={{ textTransform: "none", marginTop: 30 }}
              >
                Panele Dön
              </Button>
            </Grid>
          </Paper>

          <Card
            variant="outlined"
            style={{
              borderRadius: 20,
              padding: 30,
              margin: 10,
              marginBottom: 50,
              marginTop: 30,
            }}
          >
            <div>
              {order && order !== null && order !== undefined && (
                <div>
                  <Typography variant="h6" style={{ marginTop: 20 }}>
                    Sipariş Durumu
                  </Typography>
                  <Typography style={{ marginTop: 15 }}>
                    {showStatus(order.status)}
                  </Typography>
                  <Typography variant="h6" style={{ marginTop: 30 }}>
                    Sipariş Bilgileri
                  </Typography>
                  <List className="list-group mb-3">
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Sipariş Kodu:</strong>{" "}
                        {order && order.transaction_id}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Toplam Tutar:</strong>{" "}
                        {order && order.amount
                          ? order.amount.toLocaleString()
                          : "0"}{" "}
                        TL
                      </ListItemText>
                    </ListItem>
                    {order && order.subscription && (
                      <ListItem>
                        <ListItemText style={{ maxWidth: 500 }}>
                          <strong>Abonelik:</strong> {order.subscription.name}
                        </ListItemText>
                      </ListItem>
                    )}
                    {order &&
                      order.gift_subscriptions &&
                      order.gift_subscriptions.length > 0 && (
                        <ListItem>
                          <ListItemText style={{ maxWidth: 500 }}>
                            <strong>Hediye Abonelik:</strong>{" "}
                            {order.gift_subscriptions.map((sub, index) => {
                              // Instead of showing the generic subscription name, display "UbiTray"
                              const displayName = "UbiTray Aboneliği";

                              // Get a cleaner parent name if available
                              let parentName = "";
                              if (sub.parent_subscription_name) {
                                const fullParentName =
                                  sub.parent_subscription_name;
                                parentName = fullParentName.includes("for ")
                                  ? fullParentName.split("for ")[0].trim()
                                  : fullParentName;
                              }

                              return (
                                <React.Fragment
                                  key={sub.id || `gift-sub-${index}`}
                                >
                                  {index > 0 && ", "}
                                  <span>
                                    {displayName}
                                    {parentName && (
                                      <span
                                        style={{
                                          color: "#666",
                                          fontStyle: "italic",
                                          fontSize: "0.9em",
                                        }}
                                      >
                                        {" "}
                                        (Hediye)
                                      </span>
                                    )}
                                  </span>
                                </React.Fragment>
                              );
                            })}
                          </ListItemText>
                        </ListItem>
                      )}
                  </List>
                  <Typography variant="h6" style={{ marginTop: 30 }}>
                    Adres Bilgileri
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Adres:</strong> {order && order.address_line_1}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Şehir:</strong> {order && order.city}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>İlçe:</strong> {order && order.district}
                      </ListItemText>
                    </ListItem>
                  </List>
                  {renderUbiTrays(order)}
                  <Typography variant="h6" style={{ marginTop: 30 }}>
                    Ek Bilgiler
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Kargo Ücreti:</strong>{" "}
                        {order && order.shipping_price
                          ? order.shipping_price.toLocaleString()
                          : "0"}{" "}
                        TL
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Teslimat Süresi:</strong>{" "}
                        {order && order.shipping_time}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Telefon:</strong>{" "}
                        {order && order.telephone_number}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Sipariş Tarihi:</strong>{" "}
                        {order && order.date_issued
                          ? dayjs(order.date_issued).format("LLL")
                          : "-"}
                      </ListItemText>
                    </ListItem>
                  </List>
                  <Typography
                    variant="h6"
                    style={{ marginTop: 30, marginBottom: 20 }}
                  >
                    Sipariş Öğeleri
                  </Typography>
                  {order &&
                    order.order_items &&
                    order.order_items.map((order_item, index) => (
                      <div key={index}>
                        <Divider style={{ marginBottom: 20 }} />
                        <Typography>
                          {order_item.name.split(" - ")[0]}
                        </Typography>
                        <List>
                          <ListItem>
                            <ListItemText style={{ maxWidth: 500 }}>
                              <strong>Tutar:</strong>{" "}
                              {order_item.price.toLocaleString()} TL
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText style={{ maxWidth: 500 }}>
                              <strong>Adet:</strong> {order_item.count}
                            </ListItemText>
                          </ListItem>
                          {order_item.selected_options && (
                            <ListItem>
                              <ListItemText style={{ maxWidth: 500 }}>
                                <strong>Seçilen Opsiyonlar:</strong>
                                <div style={{ marginTop: 5 }}>
                                  {typeof order_item.selected_options ===
                                  "string"
                                    ? order_item.selected_options
                                        .split(",")
                                        .map((option, i) => {
                                          const parts = option
                                            .trim()
                                            .split(":");
                                          if (parts.length === 2) {
                                            return (
                                              <div key={i}>
                                                <strong>
                                                  {parts[0].trim()}:
                                                </strong>{" "}
                                                {parts[1].trim()}
                                              </div>
                                            );
                                          }
                                          return (
                                            <div key={i}>{option.trim()}</div>
                                          );
                                        })
                                    : typeof order_item.selected_options ===
                                      "object"
                                    ? Object.entries(
                                        order_item.selected_options
                                      ).map(([key, value], i) => {
                                        // Try to make the option key more readable
                                        let optionName = key;
                                        // Common option names to translate from IDs
                                        const optionNameMap = {
                                          1: "Renk",
                                          2: "Model",
                                          3: "İklimlendirme",
                                          4: "Ek Özellik",
                                          5: "Paket",
                                          6: "Boyut",
                                          // Add more mappings as needed
                                        };

                                        if (optionNameMap[key]) {
                                          optionName = optionNameMap[key];
                                        }

                                        // Try to make the value more readable
                                        let displayValue = value;

                                        // Common value mappings based on option type
                                        const valueMap = {
                                          // İklimlendirme values
                                          3: {
                                            5: "Var",
                                            6: "Yok",
                                          },
                                          // Model values
                                          2: {
                                            3: "Base",
                                            4: "Pro",
                                          },
                                          // Add more mappings as needed
                                        };

                                        if (
                                          valueMap[key] &&
                                          valueMap[key][value]
                                        ) {
                                          displayValue = valueMap[key][value];
                                        }

                                        return (
                                          <div key={i}>
                                            <strong>{optionName}:</strong>{" "}
                                            {displayValue}
                                          </div>
                                        );
                                      })
                                    : null}
                                </div>
                              </ListItemText>
                            </ListItem>
                          )}

                          {/* Display chosen UbiTrays if available */}
                          {order_item.chosen_ubi_trays &&
                            Object.keys(order_item.chosen_ubi_trays).length >
                              0 && (
                              <ListItem>
                                <ListItemText style={{ maxWidth: 500 }}>
                                  {console.log(
                                    "DEBUG: Rendering chosen UbiTrays for order item:",
                                    order_item.name
                                  )}
                                  {console.log(
                                    "DEBUG: UbiTrays data:",
                                    order_item.chosen_ubi_trays
                                  )}
                                  <strong>Seçilen UbiTrayler:</strong>
                                  <div style={{ marginTop: 5 }}>
                                    {Object.entries(order_item.chosen_ubi_trays)
                                      .filter(([_, qty]) => qty > 0)
                                      .map(([trayId, quantity], i) => (
                                        <div key={i}>
                                          <strong>UbiTray #{trayId}:</strong>{" "}
                                          {quantity} adet
                                        </div>
                                      ))}
                                  </div>
                                </ListItemText>
                              </ListItem>
                            )}

                          <Divider />
                        </List>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    );
  }
};

const mapStateToProps = (state) => ({
  order: state.orders.order,
  status: state.orders.status,
  ubiTrayProducts: state.products.ubiTrayProducts,
});

export default connect(mapStateToProps, {
  get_order_detail,
  get_ubitray,
})(OrderItemDetail);
