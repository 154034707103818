import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";

const CartItem = ({
  item,
  count,
  update_item,
  remove_item,
  setAlert,
  render,
  setRender,
  showViewProduct = true,
  showRemoveProduct = true,
  showUpdateProduct = true,
  showQuantity = false,
  isAuthenticated = false,
}) => {
  const [itemCount, setItemCount] = useState(1);

  useEffect(() => {
    if (count) setItemCount(count);
  }, [count]);

  // For display, we use the values provided by the backend.
  const formattedName = item.formatted_name || "";
  const isSubscription = item.purchase_method === "subscription";

  // Calculate final price considering selected options
  const calculateFinalPrice = () => {
    // Calculate base price + options price
    let basePrice = 0;
    if (isSubscription && item.subscription_plan) {
      basePrice = parseFloat(item.subscription_plan.monthly_price || 0);
    }
    // Check for nested product structure
    else if (
      item.product?.product?.price &&
      !isNaN(parseFloat(item.product.product.price))
    ) {
      // Handle nested product.product.price structure
      basePrice = parseFloat(item.product.product.price || 0);
    }
    // Try direct product.price as fallback
    else if (item.product?.price && !isNaN(parseFloat(item.product.price))) {
      // Only use product price if it's actually defined and valid
      basePrice = parseFloat(item.product.price || 0);
    }
    // For one-time purchases, we'll rely entirely on option prices

    // Calculate additional costs from selected options
    let additionalCost = 0;
    if (item.selected_values && item.product && item.product.options) {
      for (const option of item.product.options) {
        const optionId = option.id.toString();
        if (optionId in item.selected_values) {
          const valueId = item.selected_values[optionId];
          const selectedValue = option.values.find(
            (v) => v.id.toString() === valueId.toString()
          );

          if (selectedValue) {
            // For subscription items, add additional_subscription_price if available
            if (isSubscription && selectedValue.additional_subscription_price) {
              const addPrice = parseFloat(
                selectedValue.additional_subscription_price || 0
              );
              additionalCost += addPrice;
            }
            // For one-time purchases, add additional_price if available
            else if (selectedValue.additional_price) {
              const addPrice = parseFloat(selectedValue.additional_price || 0);
              additionalCost += addPrice;
            }
          }
        }
      }
    }

    // Always calculate our version of the final price using base + options
    const calculatedPrice = basePrice + additionalCost;

    // If backend provided a final_price AND user is authenticated, check for a significant difference
    if (
      isAuthenticated &&
      item.final_price !== undefined &&
      item.final_price !== null
    ) {
      const backendPrice = parseFloat(item.final_price);
      const diff = Math.abs(calculatedPrice - backendPrice);

      // Backend price is considered authoritative for logged-in users
      return backendPrice;
    }

    // If no backend price or not logged in, use our calculation
    return calculatedPrice;
  };

  const finalPrice = calculateFinalPrice();

  // Get the display image:
  // If the item is a subscription, use the subscription plan's image; otherwise, use the product image.
  const getDisplayImage = () => {
    // Check for selected option images first (highest priority)
    if (item.selected_values && item.product && item.product.options) {
      // Get all options with their selected values and images
      const optionsWithImages = [];

      // Collect all selected options with images
      for (const optionId in item.selected_values) {
        const valueId = item.selected_values[optionId];
        const option = item.product.options.find(
          (opt) => opt.id.toString() === optionId
        );

        if (option && option.values) {
          const selectedValue = option.values.find(
            (val) => val.id.toString() === valueId.toString()
          );
          if (selectedValue && selectedValue.image) {
            optionsWithImages.push({
              optionId: parseInt(optionId, 10),
              valueId: parseInt(valueId, 10),
              image: selectedValue.image,
            });
          }
        }
      }

      // Sort by option ID (descending) - this prioritizes the latest/highest option ID
      if (optionsWithImages.length > 0) {
        optionsWithImages.sort((a, b) => b.optionId - a.optionId);
        return optionsWithImages[0].image;
      }
    }

    // Next check for subscription plan images
    if (isSubscription && item.subscription_plan) {
      // First check if subscription plan has its own image
      if (item.subscription_plan.image) {
        return item.subscription_plan.image;
      }
    }

    // Check for product images
    if (item.product) {
      // First try main image
      if (item.product.image) {
        return item.product.image;
      }

      // Then try alternative images
      for (let i = 1; i <= 8; i++) {
        const imageKey = `image_${i}`;
        if (item.product[imageKey]) {
          return item.product[imageKey];
        }
      }

      // Handle nested product (for non-authenticated scenarios)
      if (item.product.product && item.product.product.image) {
        return item.product.product.image;
      }
    }

    // Return null instead of empty string when no image is found
    return null;
  };

  // Handle quantity change.
  const handleSelectChange = async (e) => {
    const newCount = parseInt(e.target.value, 10);
    setItemCount(newCount);
    try {
      await update_item(item, newCount);
      setRender(!render);
    } catch (err) {
      setAlert("Bu üründe güncelleme yapılırken hata oluştu", "error");
    }
  };

  const renderQuantitySelector = () => {
    if (showUpdateProduct) {
      return (
        <Select
          name="item_count"
          value={itemCount}
          onChange={handleSelectChange}
        >
          {[...Array(9)].map((_, i) => (
            <MenuItem key={i + 1} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
      );
    }
    return null;
  };

  const removeItemHandler = async () => {
    await remove_item(item);
    setRender(!render);
  };

  const showRemoveProductButton = () => {
    if (showRemoveProduct) {
      return (
        <Grid item>
          <IconButton color="error" onClick={removeItemHandler}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      );
    }
    return null;
  };

  // For items with additional configurations (e.g. chosen UbiTrays), we still render them.
  const renderChosenUbitrays = () => {
    if (!item.chosen_ubi_trays) return null;

    // Get product data from the item if available
    const getProductNameById = (id) => {
      // First try from subscription_plan.products if item is a subscription
      if (
        isSubscription &&
        item.subscription_plan &&
        item.subscription_plan.products
      ) {
        const product = item.subscription_plan.products.find(
          (p) => p.id === parseInt(id) || p.id.toString() === id.toString()
        );
        if (product) return product.name;
      }

      // If not found, try alternative data sources
      // Look in chosen_ubi_trays for name property
      if (Array.isArray(item.chosen_ubi_trays)) {
        const tray = item.chosen_ubi_trays.find(
          (t) =>
            (t.id === parseInt(id) || t.id.toString() === id.toString()) &&
            t.name
        );
        if (tray && tray.name) return tray.name;
      }

      // Fallback to a generic name with ID
      return `UbiTray #${id}`;
    };

    // Handle different formats of chosen_ubi_trays
    let traysMap = new Map(); // Map to consolidate by id

    // Special case for UbiHouse and similar non-array object formats with quantities
    if (
      item.chosen_ubi_trays &&
      typeof item.chosen_ubi_trays === "object" &&
      !Array.isArray(item.chosen_ubi_trays)
    ) {
      // Handle object format (e.g. {1: 2, 2: 3} where key is ID and value is quantity)
      Object.entries(item.chosen_ubi_trays)
        .filter(([id, qty]) => qty > 0) // Only include positive quantities
        .forEach(([id, qty]) => {
          traysMap.set(id.toString(), {
            id,
            name: getProductNameById(id),
            quantity: parseInt(qty, 10),
          });
        });
    }
    // Handle array formats
    else if (Array.isArray(item.chosen_ubi_trays)) {
      // Process all array items and consolidate by ID
      item.chosen_ubi_trays.forEach((tray) => {
        // Extract id and quantity based on the format
        let id, quantity, name;

        if (typeof tray === "object" && tray.id !== undefined) {
          id = tray.id.toString();
          quantity = tray.quantity || 1;
          name = tray.name || getProductNameById(id);
        } else {
          id = tray.toString();
          quantity = 1;
          name = getProductNameById(id);
        }

        // Add to or update the map
        if (traysMap.has(id)) {
          const existing = traysMap.get(id);
          traysMap.set(id, {
            ...existing,
            quantity: existing.quantity + quantity,
          });
        } else {
          traysMap.set(id, { id, name, quantity });
        }
      });
    }

    // Convert map to array for display
    const displayTrays = Array.from(traysMap.values());

    // If no trays to display, return null
    if (displayTrays.length === 0) return null;

    // Render the trays
    return displayTrays.map((tray, index) => (
      <Typography
        key={index}
        variant="body2"
        style={{ marginLeft: 5, display: "inline" }}
      >
        {tray.name}
        {tray.quantity > 1 ? ` (${tray.quantity})` : ""}
        {index < displayTrays.length - 1 ? ", " : ""}
      </Typography>
    ));
  };

  const ExtraConfigurations = () => {
    return (
      <>
        {isSubscription && item.subscription_plan && (
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={1}
            style={{ marginTop: 8 }}
          >
            <Grid item>
              <Chip
                icon={<SubscriptionsIcon />}
                label={`Abonelik: ${item.subscription_plan.name}`}
                color="primary"
                size="small"
                variant="outlined"
              />
            </Grid>
          </Grid>
        )}

        {item.chosen_ubi_trays &&
          ((Array.isArray(item.chosen_ubi_trays) &&
            item.chosen_ubi_trays.length > 0) ||
            (!Array.isArray(item.chosen_ubi_trays) &&
              Object.keys(item.chosen_ubi_trays).length > 0)) && (
            <Grid container direction="row" style={{ marginTop: 8 }}>
              <Typography variant="body2">UbiTray: </Typography>
              {renderChosenUbitrays()}
            </Grid>
          )}

        {isSubscription && item.subscription_plan && (
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginTop: 4 }}
          >
            {item.subscription_plan.minimum_months} ay minimum abonelik
            {item.subscription_plan.delivery_interval > 1 &&
              `, ${item.subscription_plan.delivery_interval} ayda bir teslimat`}
          </Typography>
        )}
      </>
    );
  };

  // Render differently based on window width.
  const width = window.innerWidth;
  const displayImage = getDisplayImage();

  if (width > 800) {
    return (
      <Grid container justifyContent="center">
        <Grid container justifyContent="center" alignItems="center">
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              width: 800,
              minHeight: 200,
              marginTop: 5,
              padding: 2,
              borderRadius: 5,
            }}
          >
            {!isSubscription ? (
              <CardActionArea
                href={`/product/${
                  (item.product &&
                    (item.product.slug ||
                      (item.product.product && item.product.product.slug))) ||
                  ""
                }`}
              >
                <CardMedia
                  component="img"
                  style={{
                    maxWidth: 150,
                    minWidth: 150,
                    minHeight: 150,
                    maxHeight: 150,
                  }}
                  image={
                    displayImage ||
                    (isSubscription
                      ? "/static/images/default_subscription.jpg"
                      : "/static/images/default_product.jpg")
                  }
                  alt={isSubscription ? "Abonelik görseli" : "Ürün görseli"}
                />
              </CardActionArea>
            ) : (
              <CardMedia
                component="img"
                style={{
                  maxWidth: 150,
                  minWidth: 150,
                  minHeight: 150,
                  maxHeight: 150,
                }}
                image={
                  displayImage ||
                  (isSubscription
                    ? "/static/images/default_subscription.jpg"
                    : "/static/images/default_product.jpg")
                }
                alt={isSubscription ? "Abonelik görseli" : "Ürün görseli"}
              />
            )}
            <Grid item sx={{ minWidth: 350, alignSelf: "center", mr: 2 }}>
              <Typography variant="h6">{formattedName}</Typography>
              {!isSubscription && (
                <Typography variant="subtitle2" sx={{ color: "#548346" }}>
                  {item.product &&
                  (item.product.quantity > 0 ||
                    (item.product.product && item.product.product.quantity > 0))
                    ? "Stokta var"
                    : "Stokta yok"}
                </Typography>
              )}
              <ExtraConfigurations />
            </Grid>
            <Grid item sx={{ minWidth: 250, alignSelf: "center", mr: 2 }}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {renderQuantitySelector()}
                <Grid item sx={{ mx: 2 }}>
                  <Typography>
                    {finalPrice.toLocaleString("tr-TR")} ₺
                    {isSubscription && (
                      <span style={{ fontSize: "0.8rem" }}>/ay</span>
                    )}
                  </Typography>
                </Grid>
                {showRemoveProductButton()}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container justifyContent="center">
        <Grid container justifyContent="center" alignItems="center">
          <Card
            variant="outlined"
            sx={{ display: "flex", width: "95%", minHeight: 200, mt: 5, p: 2 }}
          >
            {item.purchase_method !== "subscription" ? (
              <CardActionArea
                href={`/product/${
                  (item.product &&
                    (item.product.slug ||
                      (item.product.product && item.product.product.slug))) ||
                  ""
                }`}
              >
                <CardMedia
                  component="img"
                  style={{ width: "100%", mt: 2 }}
                  image={
                    displayImage ||
                    (isSubscription
                      ? "/static/images/default_subscription.jpg"
                      : "/static/images/default_product.jpg")
                  }
                  alt={isSubscription ? "Abonelik görseli" : "Ürün görseli"}
                />
              </CardActionArea>
            ) : (
              <CardMedia
                component="img"
                style={{ width: "100%", mt: 2 }}
                image={
                  displayImage ||
                  (isSubscription
                    ? "/static/images/default_subscription.jpg"
                    : "/static/images/default_product.jpg")
                }
                alt={isSubscription ? "Abonelik görseli" : "Ürün görseli"}
              />
            )}
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6">{formattedName}</Typography>
                {item.purchase_method !== "subscription" && (
                  <Typography variant="subtitle2" sx={{ color: "#548346" }}>
                    {item.product &&
                    (item.product.quantity > 0 ||
                      (item.product.product &&
                        item.product.product.quantity > 0))
                      ? "Stokta var"
                      : "Stokta yok"}
                  </Typography>
                )}
                <ExtraConfigurations />
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="center">
                  {renderQuantitySelector()}
                  <Grid item sx={{ mx: 2 }}>
                    <Typography>
                      {finalPrice.toLocaleString("tr-TR")} ₺
                      {item.purchase_method === "subscription" && (
                        <span style={{ fontSize: "0.8rem" }}>/ay</span>
                      )}
                    </Typography>
                  </Grid>
                  {showRemoveProductButton()}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }
};

export default CartItem;
