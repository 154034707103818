import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setAlert } from "../actions/alert";
import {
  remove_item,
  update_item,
  get_items,
  get_item_total,
  get_total,
} from "../actions/cart";
import CartItem from "../components/CartItem";
import { Button, Typography, Grid, Divider, Box } from "@mui/material";
import Header from "../components/Header";
import SepetComponent from "../components/Sepet";
import { Helmet } from "react-helmet";

const Cart = ({
  isAuthenticated,
  items,
  amount, // Now can be { total_cost, total_compare_at, items_count } or old format { one_time_total, subscription_monthly_total }
  total_items,
  remove_item,
  update_item,
  get_items,
  get_item_total,
  get_total,
}) => {
  const [render, setRender] = useState(false);

  // Fetch cart items and totals on render or when render state toggles.
  useEffect(() => {
    // Add error handling for API calls
    try {
      get_items();
      get_item_total();
      get_total();
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  }, [render]);

  // Handle different format for amount:
  // New backend: { total_cost, one_time_total, subscription_monthly_total, items_count }
  // Old backend: { one_time_total, subscription_monthly_total }
  // Fallback: numeric value for total

  // Safe check for subscription items
  const hasSubscriptionItems =
    items &&
    Array.isArray(items) &&
    items.some((item) => item && item.purchase_method === "subscription");

  // Helper function to calculate the final price consistently for any cart item
  const calculateItemFinalPrice = (item) => {
    // Calculate base price
    let basePrice = 0;
    if (item.purchase_method === "subscription" && item.subscription_plan) {
      basePrice = parseFloat(item.subscription_plan.monthly_price || 0);
    }
    // Check for nested product structure
    else if (
      item.product?.product?.price &&
      !isNaN(parseFloat(item.product.product.price))
    ) {
      // Handle nested product.product.price structure
      basePrice = parseFloat(item.product.product.price || 0);
    }
    // Try direct product.price as fallback
    else if (item.product?.price && !isNaN(parseFloat(item.product.price))) {
      // Only use product price if it's actually defined and valid
      basePrice = parseFloat(item.product.price || 0);
    } else {
      // For one-time purchases, we'll rely entirely on option prices
    }

    // Calculate additional costs from selected options
    let additionalCost = 0;
    if (item.selected_values && item.product && item.product.options) {
      for (const option of item.product.options) {
        const optionId = option.id.toString();
        if (optionId in item.selected_values) {
          const valueId = item.selected_values[optionId];
          const selectedValue = option.values.find(
            (v) => v.id.toString() === valueId.toString()
          );

          if (selectedValue) {
            // For subscription items, add additional_subscription_price if available
            if (
              item.purchase_method === "subscription" &&
              selectedValue.additional_subscription_price
            ) {
              const addPrice = parseFloat(
                selectedValue.additional_subscription_price || 0
              );
              additionalCost += addPrice;
            }
            // For one-time purchases, add additional_price if available
            else if (selectedValue.additional_price) {
              const addPrice = parseFloat(selectedValue.additional_price || 0);
              additionalCost += addPrice;
            } else {
            }
          } else {
          }
        }
      }
    } else {
    }

    // Always calculate our version of the final price using base + options
    const calculatedPrice = basePrice + additionalCost;

    // If backend provided a final_price AND user is authenticated, check for a significant difference
    if (
      isAuthenticated &&
      item.final_price !== undefined &&
      item.final_price !== null
    ) {
      const backendPrice = parseFloat(item.final_price);
      const diff = Math.abs(calculatedPrice - backendPrice);

      // Backend price is considered authoritative for logged-in users
      return backendPrice;
    }

    // If no backend price or not logged in, use our calculation
    return calculatedPrice;
  };

  // Extract the total values from the backend response
  let totalCost = 0;
  let oneTimeTotal = 0;
  let subscriptionTotal = 0;

  // Calculate totals from items with consistent pricing
  if (items && Array.isArray(items) && items.length > 0) {
    items.forEach((item) => {
      const itemFinalPrice = calculateItemFinalPrice(item);
      const itemTotal = itemFinalPrice * (item.count || 1);

      totalCost += itemTotal;

      if (item.purchase_method === "subscription") {
        subscriptionTotal += itemTotal;
      } else {
        oneTimeTotal += itemTotal;
      }
    });
  }
  // If no items, use backend totals if available
  else if (
    amount &&
    typeof amount === "object" &&
    amount.total_cost !== undefined
  ) {
    totalCost = parseFloat(amount.total_cost) || 0;

    // Check if backend is providing the separate totals
    if (
      amount.one_time_total !== undefined &&
      amount.subscription_monthly_total !== undefined
    ) {
      oneTimeTotal = parseFloat(amount.one_time_total) || 0;
      subscriptionTotal = parseFloat(amount.subscription_monthly_total) || 0;
    } else {
      // Fallback for old backend format
      oneTimeTotal = totalCost;
      subscriptionTotal = 0;
    }
  }
  // Old backend response format
  else if (amount && typeof amount === "object") {
    oneTimeTotal = parseFloat(amount.one_time_total) || 0;
    subscriptionTotal = parseFloat(amount.subscription_monthly_total) || 0;
    totalCost = oneTimeTotal + subscriptionTotal;
  }
  // Direct numeric value
  else if (amount && typeof amount === "number") {
    totalCost = amount;
    oneTimeTotal = amount;
  }
  // No items and no backend totals
  else {
  }

  // Final total is just total_cost now
  const combinedTotal = totalCost;

  const showItems = () => {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Sepet sayfası" />
          <title>Sepetim - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/sepetim" />
        </Helmet>
        <Typography variant="h3">Sepet Toplamı</Typography>
        <Typography
          variant="h6"
          style={{ color: "#AFAFAF", marginTop: 10, marginBottom: 20 }}
        >
          Sepetinizde {total_items} ürün var
        </Typography>
        {/* Display total */}
        <Typography variant="h4" style={{ color: "#196DDD" }}>
          {combinedTotal.toLocaleString() + " ₺"}
        </Typography>
        {hasSubscriptionItems && (
          <Box mt={1} mb={2}>
            <Typography variant="body2" color="textSecondary">
              * Abonelik ürünleri için gösterilen fiyat aylık tutardır
            </Typography>
          </Box>
        )}
        {checkoutButton()}
        <Divider
          light
          flexItem
          style={{ height: 0.1, marginTop: 50, marginBottom: 50 }}
        />
        {items &&
          Array.isArray(items) &&
          items.length > 0 &&
          items.map((item, index) => {
            if (!item) return null;
            const count = item.count || 1;
            return (
              <div key={index}>
                <CartItem
                  item={item}
                  count={count}
                  update_item={update_item}
                  remove_item={remove_item}
                  setAlert={setAlert}
                  render={render}
                  setRender={setRender}
                />
              </div>
            );
          })}
      </Grid>
    );
  };

  const checkoutButton = () => {
    if (total_items < 1) {
      return (
        <Button
          variant="contained"
          href="/"
          style={{
            margin: 30,
            height: 50,
            width: 200,
            backgroundColor: "#1683FC",
            color: "#fff",
            borderRadius: 10,
            textTransform: "none",
          }}
        >
          Anasayfaya geri dön
        </Button>
      );
    } else if (!isAuthenticated) {
      return (
        <Button
          variant="contained"
          href="/login"
          style={{
            margin: 30,
            height: 50,
            width: 200,
            backgroundColor: "#1683FC",
            color: "#fff",
            borderRadius: 10,
          }}
        >
          SEPETİ ONAYLA
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          href="/checkout"
          style={{
            margin: 30,
            height: 50,
            width: 200,
            backgroundColor: "#1683FC",
            color: "#fff",
            borderRadius: 10,
          }}
        >
          ÖDEMEYE İLERLEYİN
        </Button>
      );
    }
  };

  const cartRender = () => {
    if (total_items < 1) {
      return (
        <div>
          <Header />
          <SepetComponent />
        </div>
      );
    } else {
      return (
        <div>
          <Header />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 60, marginBottom: 30 }}
          >
            <Grid container justifyContent="center" alignItems="center">
              {showItems()}
              <Grid style={{ marginTop: 100 }}>
                <Divider
                  light
                  style={{
                    marginTop: 20,
                    marginBottom: 30,
                    width: "100%",
                    maxWidth: 900,
                    height: 0.1,
                  }}
                />
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ maxWidth: 800 }}
                >
                  {/* Display summary for one-time items */}
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      marginRight: 30,
                      paddingLeft: 20,
                      maxWidth: 400,
                    }}
                  >
                    <Typography>Tek Seferlik Ürün Toplamı</Typography>
                    <Typography>
                      {oneTimeTotal.toLocaleString() + " ₺"}
                    </Typography>
                  </Grid>
                  {/* Display summary for subscription items if present */}
                  {hasSubscriptionItems && (
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      style={{
                        marginRight: 30,
                        paddingLeft: 20,
                        maxWidth: 400,
                      }}
                    >
                      <Typography>Abonelik Ürünleri (Aylık)</Typography>
                      <Typography>
                        {subscriptionTotal.toLocaleString()} ₺/ay
                      </Typography>
                    </Grid>
                  )}
                  <Divider
                    light
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      width: "100%",
                      maxWidth: 450,
                      height: 0.1,
                    }}
                  />
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      marginRight: 30,
                      paddingLeft: 20,
                      maxWidth: 400,
                    }}
                  >
                    <Typography variant="h5">Toplam</Typography>
                    <Typography variant="h5">
                      {combinedTotal.toLocaleString() + " ₺"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-end"
                    style={{ maxWidth: 400 }}
                  >
                    {checkoutButton()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  };

  return <div>{cartRender()}</div>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  items: state.cart.items,
  amount: state.cart.amount, // now an object with one_time_total and subscription_monthly_total
  total_items: state.cart.total_items,
});

export default connect(mapStateToProps, {
  remove_item,
  update_item,
  get_items,
  get_item_total,
  get_total,
  setAlert,
})(Cart);
