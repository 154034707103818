import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { get_ubitray, get_filtered_products } from "../actions/products";
import {
  add_item,
  get_items,
  get_total,
  get_item_total,
} from "../actions/cart";
import { getUbiTraySubscriptionPlans } from "../actions/subscriptions";
import { get_categories } from "../actions/categories";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Grid, Typography, Card, Button } from "@mui/material";
import UbiTrayCard from "../components/UbiTrayCard";
import UbiTrayModalSelector from "../components/UbiTrayModalSelector";
import { Helmet } from "react-helmet";

const UbiTray = ({
  get_categories,
  ubiTrayProducts,
  get_ubitray,
  add_item,
  get_items,
  get_total,
  get_item_total,
  subscriptionPlans,
  getUbiTraySubscriptionPlans, // Added this to properly destructure the prop
}) => {
  const [redirect, setRedirect] = useState(false);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [filtered] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);

  useEffect(() => {
    get_categories();
    get_ubitray(); // fetch UbiTray products
    window.scrollTo(0, 0);
  }, [get_categories, get_ubitray]);

  // When products are loaded, fetch the subscription plan for the first product.
  useEffect(() => {
    if (ubiTrayProducts && ubiTrayProducts.length > 0) {
      const categoryId = ubiTrayProducts[0].category;
      getUbiTraySubscriptionPlans(categoryId); // Now this will work correctly
    }
  }, [ubiTrayProducts, getUbiTraySubscriptionPlans]);

  // When subscriptionPlans update, select the appropriate subscription plan.
  useEffect(() => {
    if (ubiTrayProducts && ubiTrayProducts.length > 0) {
      // Use the category id instead of the product id for the lookup.
      const categoryId = ubiTrayProducts[0].category;
      const plansForCategory =
        subscriptionPlans && subscriptionPlans[categoryId];
      if (plansForCategory && plansForCategory.length > 0) {
        setSubscriptionPlan(plansForCategory[0]);
      } else {
        console.warn(
          "No subscription plans available for category id:",
          categoryId
        );
        setSubscriptionPlan(null);
      }
    }
  }, [subscriptionPlans, ubiTrayProducts]);

  const showUbiTrays = () => {
    return (
      !filtered &&
      ubiTrayProducts &&
      ubiTrayProducts.map((tray, index) => (
        <div key={index}>
          <UbiTrayCard
            product={tray}
            add_item={add_item}
            get_items={get_items}
            get_total={get_total}
            get_item_total={get_item_total}
            setRedirect={setRedirect}
          />
        </div>
      ))
    );
  };

  // Helper function to convert selected trays to an array format.
  const convertSelectedToArray = (selected) => {
    return Object.entries(selected)
      .map(([id, qty]) => ({ id: parseInt(id, 10), quantity: qty }))
      .filter((item) => item.quantity > 0)
      .sort((a, b) => a.id - b.id);
  };

  const handleSubscriptionSelection = (selected, action) => {
    setSubscriptionModalOpen(false);

    if (action === "save" && Object.keys(selected).length > 0) {
      if (!subscriptionPlan) {
        console.error("No subscription plan available");
        return;
      }

      // Use the first UbiTray product as the main product
      const mainProduct = ubiTrayProducts[0];

      // Build base payload for a subscription.
      let payload = {
        purchaseMethod: "subscription",
        subscriptionPlanId: subscriptionPlan.id,
        chosenUbiTrays: convertSelectedToArray(selected),
      };

      // Only include product details if the subscription plan is product-based.
      // For UbiTray subscriptions (category-based), omit productId.
      if (!subscriptionPlan.category) {
        payload = {
          ...payload,
          productId: mainProduct.id,
          productSlug: mainProduct.slug,
          product: mainProduct,
        };
      }

      // Call the add_item action.
      add_item(payload);
      setRedirect(true);
    }
  };

  if (redirect) return <Redirect to="/cart-or-continue-shopping" />;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="UbiTray ile yeni nesil tohumlar ile tanışın; ekme, biçme gibi konularla uğraşmadan sebze ve meyve üretin."
        />
        <title>UbiTray Liste - Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com/ubitray" />
      </Helmet>
      <Header />
      <Grid container direction="column" style={{ backgroundColor: "#fff" }}>
        <Grid
          item
          container
          direction="row"
          style={{ maxWidth: 1400, alignSelf: "center" }}
        >
          {/* Subscription Banner Section */}
          <Grid
            lg={12}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 40, marginBottom: 20 }}
          >
            <Card
              variant="contained"
              style={{ backgroundColor: "#f9f9f9" }}
              sx={{
                p: 3,
                borderRadius: 2,
                width: "90%",
                maxWidth: 1200,
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container direction="column" alignItems="center">
                <Typography variant="h5" gutterBottom>
                  UbiTray Abonelik Seçeneği
                </Typography>
                <Typography variant="body1" style={{ textAlign: "center" }}>
                  Üç adet UbiTray seçin, her üç ayda bir kapınıza teslim edelim.
                </Typography>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: { xs: 2, md: 0 },
                  ml: { xs: 0, md: 2 },
                  width: 200,
                  height: 45,
                  borderRadius: 3,
                  textTransform: "none",
                  backgroundColor: "#1683FC",
                }}
                onClick={() => setSubscriptionModalOpen(true)}
              >
                Abonelik ile Seç
              </Button>
            </Card>
          </Grid>

          {/* UbiTray Products List */}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 50, marginBottom: 100 }}
          >
            {showUbiTrays()}
          </Grid>
        </Grid>
      </Grid>
      <Footer />

      {/* Subscription Modal */}
      {subscriptionModalOpen && (
        <UbiTrayModalSelector
          open={subscriptionModalOpen}
          onClose={handleSubscriptionSelection}
          ubiTrayProducts={ubiTrayProducts}
          initialSelected={{}}
          subscription={true} // indicates subscription context
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  ubiTrayProducts: state.products.ubiTrayProducts,
  filtered_products: state.products.filtered_products,
  subscriptionPlans: state.subscriptions.productPlans,
});

export default connect(mapStateToProps, {
  get_categories,
  get_ubitray,
  get_filtered_products,
  add_item,
  get_items,
  get_total,
  get_item_total,
  getUbiTraySubscriptionPlans,
})(UbiTray);
