import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { get_items, get_total, get_item_total } from "../../actions/cart";
import {
  get_device,
  add_device,
  get_device_total,
  remove_device,
} from "../../actions/devices";
import { list_orders } from "../../actions/orders";
import { get_user_profile, update_user_profile } from "../../actions/profile";
import { logout } from "../../actions/auth";
import { getUserSubscriptions } from "../../actions/subscriptions";
import dayjs from "dayjs";
import SubscriptionCard from "../../components/SubscriptionCard";
import localizedFormat from "dayjs/plugin/localizedFormat";
import axios from "axios";
import UserProfileForm from "../../components/UserProfileForm";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DeviceListItem from "../../components/DeviceListItem";
import AddDevicePopup from "../../components/AddDevicePopup";
import { Helmet } from "react-helmet";

// Icon Imports
import QrCodeIcon from "@mui/icons-material/QrCode";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";

dayjs.extend(localizedFormat);
dayjs.locale("tr");

const Dashboard = ({
  get_items,
  get_total,
  get_item_total,
  total_items,
  items,
  list_orders,
  user,
  profile,
  logout,
  get_user_profile,
  update_user_profile,
  add_device,
  get_device,
  remove_device,
  get_device_total,
  orders,
  getUserSubscriptions, // Subscription action
  subscriptions, // User subscriptions from Redux state
}) => {
  const [display, setDisplay] = useState("user_info");

  // Form state for user profile
  const [formData, setFormData] = useState({
    address_line_1: "",
    city: "",
    district: "",
    phone: "",
  });
  const { address_line_1, city, district, phone } = formData;

  const showStatus = (status) => {
    if (status === "not_processed") {
      return <Typography style={{ color: "orange" }}>Onay bekliyor</Typography>;
    } else if (status === "processed") {
      return <Typography style={{ color: "#4DA2E6" }}>Onaylandı</Typography>;
    } else if (status === "shipped") {
      return <Typography style={{ color: "#F2B34B" }}>Kargoda</Typography>;
    } else if (status === "delivered") {
      return <Typography style={{ color: "green" }}>Gönderildi</Typography>;
    } else if (status === "cancelled") {
      return <Typography style={{ color: "red" }}>İptal edildi</Typography>;
    }
  };

  // onChange handler for controlled form fields
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // Pop-up state for adding a device
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Profile form submission handler
  const onSubmit = (e) => {
    e.preventDefault();
    update_user_profile(address_line_1, city, district, phone);
  };

  // Fetch user profile on mount
  useEffect(() => {
    get_user_profile();
  }, [get_user_profile]);

  // Fetch cart, orders if user exists
  useEffect(() => {
    if (user) {
      get_items();
      get_total();
      get_item_total();
      list_orders();
    }
  }, [user, get_items, get_total, get_item_total, list_orders]);

  // Device data fetch
  const [render, setRender] = useState(false);
  useEffect(() => {
    get_device();
    get_device_total();
  }, [render, get_device, get_device_total]);

  // Fetch subscriptions when the "subscriptions" tab is active
  useEffect(() => {
    if (display === "subscriptions") {
      getUserSubscriptions();
    }
  }, [display, getUserSubscriptions]);

  // Populate profile form data when profile is loaded
  useEffect(() => {
    if (profile) {
      setFormData({
        address_line_1: profile.address_line_1,
        city: profile.city,
        district: profile.district,
        phone: profile.phone,
      });
    }
  }, [profile]);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  const handleCancelSubscription = async (subscriptionId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/subscription/subscriptions/${subscriptionId}/cancel/`,
        {},
        config
      );
      // Optionally refresh the subscription list
      getUserSubscriptions();
      setAlert("Subscription canceled", "success");
    } catch (error) {
      setAlert("Failed to cancel subscription", "error");
    }
  };

  // Function to render the user profile information
  const userInfo = () => (
    <Grid>
      <Typography variant="h6">Kullanıcı Bilgileri</Typography>
      <Paper variant="outlined" style={{ borderRadius: 20, marginTop: 30 }}>
        <List>
          <ListItem>
            <Typography style={{ marginRight: 10, fontWeight: "bold" }}>
              Ad:
            </Typography>
            <Typography style={{ textTransform: "capitalize" }}>
              {user ? user.first_name : null}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography style={{ marginRight: 10, fontWeight: "bold" }}>
              Soyad:
            </Typography>
            <Typography style={{ textTransform: "capitalize" }}>
              {user ? user.last_name : null}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography style={{ marginRight: 10, fontWeight: "bold" }}>
              Email:
            </Typography>
            <Typography>{user ? user.email : null}</Typography>
          </ListItem>
          <Divider light style={{ marginTop: 10, marginBottom: 10 }} />
          <ListItem>
            <Grid container direction="row" alignItems="center">
              <Typography>Şifre değiştirmek için:</Typography>
              <Button href="/reset-password">Tıklayın</Button>
            </Grid>
          </ListItem>
        </List>
      </Paper>
    </Grid>
  );

  // Function to render purchase history
  const purchaseHistory = () => {
    let width = window.innerWidth;
    return (
      <div>
        <Typography variant="h6">Siparişlerim</Typography>
        <Grid container>
          {orders &&
            orders.map((order, index) => (
              <div key={index}>
                <Card
                  variant="outlined"
                  style={{
                    marginTop: 30,
                    padding: 30,
                    borderRadius: 20,
                    width: width > 800 ? 330 : "auto",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ color: "#DADCE0" }}>
                      Sipariş No: {index + 1}
                    </Typography>
                    <Paper
                      style={{
                        backgroundColor: "#3ABC0E",
                        color: "#fff",
                        padding: 10,
                      }}
                    >
                      <Typography>
                        {order.amount.toLocaleString()} TL
                      </Typography>
                    </Paper>
                  </Grid>
                  <List>
                    <ListItem>
                      <ListItemText>Sipariş Durumu:</ListItemText>
                      <ListItemText style={{ marginLeft: -60 }}>
                        {showStatus(order.status)}
                      </ListItemText>
                    </ListItem>
                    <List
                      subheader={<ListSubheader>Sipariş Detayı:</ListSubheader>}
                    >
                      <ListItem>
                        <ListItemIcon>
                          <QrCodeIcon />
                        </ListItemIcon>
                        <ListItemText>
                          Sipariş Kodu: {order.transaction_id}
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <LocalShippingIcon />
                        </ListItemIcon>
                        <ListItemText>
                          Kargo Tutarı: {order.shipping_price.toLocaleString()}{" "}
                          TL
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <AlarmOnIcon />
                        </ListItemIcon>
                        <ListItemText>
                          Sipariş Verildi:{" "}
                          {dayjs(order.date_issued).format("LLL")}
                        </ListItemText>
                      </ListItem>
                    </List>
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        href={`/dashboard/order-detail/${order.transaction_id}`}
                        style={{ color: "#196DDD" }}
                      >
                        <ListItemText primary="Sipariş Detayı" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Card>
              </div>
            ))}
        </Grid>
      </div>
    );
  };

  // Function to render devices
  const devices = () => {
    return total_items > 0 ? (
      <Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Cihazlar</Typography>
          <Button onClick={handleClickOpen}>CİHAZ EKLE</Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">
              {"Yeni Cihaz Kayıt"}
            </DialogTitle>
            <DialogContent>
              <AddDevicePopup />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                Kapat
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        {/* Render registered devices */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Typography
            style={{ color: "#AFAFAF", marginTop: 10, marginBottom: 20 }}
          >
            Kayıtlı cihaz sayısı: {total_items}
          </Typography>
          <Divider light flexItem style={{ height: 0.1, marginBottom: 50 }} />
          {items &&
            items.map((item, index) => (
              <div key={index}>
                <DeviceListItem
                  item={item}
                  count={item.count}
                  remove_device={remove_device}
                  setAlert={setAlert}
                  render={render}
                  setRender={setRender}
                />
              </div>
            ))}
        </Grid>
      </Grid>
    ) : (
      <Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Cihazlar</Typography>
          <Button onClick={handleClickOpen}>CİHAZ EKLE</Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">
              {"Yeni Cihaz Kayıt"}
            </DialogTitle>
            <DialogContent>
              <AddDevicePopup />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                Kapat
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Paper variant="outlined" style={{ borderRadius: 20, marginTop: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography
              style={{ margin: 20, marginTop: 30, fontWeight: "bold" }}
            >
              Henüz ekli bir cihazınız yok.
            </Typography>
            <Typography
              style={{
                color: "grey",
                margin: 20,
                marginBottom: 50,
                textTransform: "none",
                textAlign: "center",
              }}
            >
              UbiHouse eklemek için "Cihaz Ekle" butonunu kullanabilirsiniz.
              Ekli cihazınızı görmüyorsanız, bizle support@ubicro.com üzerinden
              iletişime geçiniz.
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  // Function to render the user subscriptions and their items
  const subscriptionsComponent = () => (
    <Grid container direction="column" spacing={2}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Aboneliklerim
      </Typography>
      {subscriptions && subscriptions.length > 0 ? (
        subscriptions.map((subscription) => (
          <SubscriptionCard
            key={subscription.id}
            subscription={subscription}
            handleCancelSubscription={handleCancelSubscription}
            setAlert={setAlert}
          />
        ))
      ) : (
        <Typography variant="body2">
          Henüz bir aboneliğiniz bulunmamaktadır.
        </Typography>
      )}
    </Grid>
  );
  // Function to render user profile edit form
  const userProfile = () => {
    return profile ? (
      <UserProfileForm
        address_line_1={address_line_1}
        city={city}
        district={district}
        phone={phone}
        onChange={onChange}
        onSubmit={onSubmit}
        profile={profile}
      />
    ) : (
      <Fragment></Fragment>
    );
  };

  // Switch between different dashboard views based on the selected tab
  const renderData = () => {
    switch (display) {
      case "user_info":
        return <Fragment>{userInfo()}</Fragment>;
      case "profile_info":
        return <Fragment>{userProfile()}</Fragment>;
      case "purchase_history":
        return <Fragment>{purchaseHistory()}</Fragment>;
      case "devices":
        return <Fragment>{devices()}</Fragment>;
      case "subscriptions":
        return <Fragment>{subscriptionsComponent()}</Fragment>;
      default:
        return null;
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Kullanıcı paneli sayfası" />
        <title>Kullanıcı Paneli - Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com/dashboard" />
      </Helmet>
      <Header />
      <Grid
        container
        justifyContent="center"
        direction="column"
        style={{ padding: 20 }}
      >
        <Grid item>
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              direction="row"
              style={{ maxWidth: 1000 }}
            >
              <Grid item>
                <Typography>Hesap</Typography>
              </Grid>
              <Grid item>
                <Button onClick={logout} href="/">
                  Oturumu Kapat
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ maxWidth: 1000 }}
            >
              <Typography variant="h5" style={{ marginRight: 8 }}>
                Merhaba,
              </Typography>
              <Typography variant="h5" style={{ textTransform: "capitalize" }}>
                {user ? user.first_name : null}.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" light />
      <Grid container direction="row" justifyContent="center">
        <Grid
          container
          direction="row"
          style={{ padding: 20, marginTop: 50, maxWidth: 1000 }}
        >
          <Grid item xs={12} md={5}>
            <Paper
              variant="outlined"
              style={{
                padding: 20,
                borderRadius: 20,
                width: 300,
                marginBottom: 50,
              }}
            >
              <Typography variant="h6">Kullanıcı Paneli</Typography>
              <List className="list-group">
                <ListItem>
                  <ListItemButton onClick={() => setDisplay("user_info")}>
                    {display === "user_info" ? (
                      <Typography style={{ color: "#196DDD" }}>
                        Kullanıcı Bilgilerim
                      </Typography>
                    ) : (
                      <Typography>Kullanıcı Bilgilerim</Typography>
                    )}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={() => setDisplay("profile_info")}>
                    {display === "profile_info" ? (
                      <Typography style={{ color: "#196DDD" }}>
                        Adres Bilgilerim
                      </Typography>
                    ) : (
                      <Typography>Adres Bilgilerim</Typography>
                    )}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    onClick={() => setDisplay("purchase_history")}
                  >
                    {display === "purchase_history" ? (
                      <Typography style={{ color: "#196DDD" }}>
                        Siparişlerim
                      </Typography>
                    ) : (
                      <Typography>Siparişlerim</Typography>
                    )}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={() => setDisplay("devices")}>
                    {display === "devices" ? (
                      <Typography style={{ color: "#196DDD" }}>
                        Cihazlarım
                      </Typography>
                    ) : (
                      <Typography>Cihazlarım</Typography>
                    )}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={() => setDisplay("subscriptions")}>
                    {display === "subscriptions" ? (
                      <Typography style={{ color: "#196DDD" }}>
                        Aboneliklerim
                      </Typography>
                    ) : (
                      <Typography>Aboneliklerim</Typography>
                    )}
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5} style={{ marginBottom: 100 }}>
            {renderData()}
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  user: state.auth.user,
  profile: state.profile.profile,
  items: state.devices.items,
  total_items: state.devices.total_items,
  subscriptions: state.subscriptions.userSubscriptions, // Map subscriptions state
});

export default connect(mapStateToProps, {
  get_items,
  get_total,
  get_item_total,
  get_device,
  add_device,
  remove_device,
  get_device_total,
  list_orders,
  get_user_profile,
  update_user_profile,
  logout,
  setAlert,
  getUserSubscriptions, // Connect subscription action
})(Dashboard);
