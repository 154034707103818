import axios from "axios";
import { setAlert } from "./alert";
import { GET_COUPON_SUCCESS, GET_COUPON_FAIL } from "./types";

export const check_coupon = (coupon_name) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/coupon/check-coupon?coupon_name=${coupon_name}`,
      config
    );

    if (res.status === 200 && res.data.coupon) {
      dispatch({
        type: GET_COUPON_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert("Kupon kodu başarıyla uygulandı", "success"));
    } else {
      dispatch({
        type: GET_COUPON_FAIL,
      });
      if (res.data && res.data.error) {
        dispatch(setAlert(res.data.error, "error"));
      } else {
        dispatch(setAlert("Kupon kodu hatalı veya geçersiz", "error"));
      }
    }
  } catch (err) {
    dispatch({
      type: GET_COUPON_FAIL,
    });

    if (err.response) {
      console.log("Coupon error response:", err.response);

      const errorMessage =
        (err.response.data && err.response.data.error) ||
        (err.response.data && err.response.data.detail) ||
        (err.response.data && typeof err.response.data === "string"
          ? err.response.data
          : null);

      if (errorMessage) {
        dispatch(setAlert(errorMessage, "error"));
        return;
      }
    }

    dispatch(
      setAlert("Kupon kodu kontrol edilirken bir sorun oluştu", "error")
    );
  }

  window.scrollTo(0, 0);
};
