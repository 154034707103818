import axios from "axios";
import {
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAIL,
  ADD_SUBSCRIPTION_ITEM_SUCCESS,
  ADD_SUBSCRIPTION_ITEM_FAIL,
  GET_USER_SUBSCRIPTIONS_SUCCESS,
  GET_USER_SUBSCRIPTIONS_FAIL,
  GET_PRODUCT_SUBSCRIPTION_PLANS_SUCCESS,
  GET_PRODUCT_SUBSCRIPTION_PLANS_FAIL,
} from "./types";

// Fetch subscription plans for a given product
export const getProductSubscriptionPlans = (productId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/products/product/${productId}/subscription-plans`
    );
    dispatch({
      type: GET_PRODUCT_SUBSCRIPTION_PLANS_SUCCESS,
      payload: {
        productId,
        plans: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_SUBSCRIPTION_PLANS_FAIL,
    });
  }
};

export const getUbiTraySubscriptionPlans = (categoryId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/products/category/${categoryId}/subscription-plans`
    );

    dispatch({
      type: GET_PRODUCT_SUBSCRIPTION_PLANS_SUCCESS,
      payload: {
        // Use categoryId as key in the reducer.
        productId: categoryId,
        plans: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_SUBSCRIPTION_PLANS_FAIL,
    });
  }
};

export const createSubscription = () => async (dispatch) => {
  try {
    const res = await axios.post("/api/subscriptions/");
    dispatch({ type: CREATE_SUBSCRIPTION_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: CREATE_SUBSCRIPTION_FAIL });
  }
};

export const addSubscriptionItem =
  (subscriptionId, planId, quantity = 1) =>
  async (dispatch) => {
    try {
      const res = await axios.post("/api/subscription-items/", {
        user_subscription: subscriptionId,
        plan: planId,
        quantity,
      });
      dispatch({ type: ADD_SUBSCRIPTION_ITEM_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch({ type: ADD_SUBSCRIPTION_ITEM_FAIL });
    }
  };

export const getUserSubscriptions = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    console.log("DEBUG API: Fetching user subscriptions...");
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/subscription/subscriptions/`,
      config
    );
    console.log("DEBUG API: Received subscription data:", res.data);

    // Check if subscription plans have ask_choice field
    if (res.data && res.data.length > 0) {
      res.data.forEach((subscription) => {
        console.log(`DEBUG API: Subscription #${subscription.id}:`);
        console.log(`  Plan:`, subscription.plan);
        console.log(`  ask_choice:`, subscription.plan?.ask_choice);
        console.log(`  chosen_ubi_trays:`, subscription.chosen_ubi_trays);
      });
    }

    dispatch({ type: GET_USER_SUBSCRIPTIONS_SUCCESS, payload: res.data });
  } catch (err) {
    console.error("DEBUG API: Error fetching subscriptions", err);
    dispatch({ type: GET_USER_SUBSCRIPTIONS_FAIL });
  }
};

// Update UbiTray selections for a subscription
export const updateSubscriptionUbiTrays =
  (subscriptionId, ubiTrays) => async (dispatch, getState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    try {
      // Find the subscription in the store to check if it supports UbiTray selection
      const state = getState();
      const subscription = state.subscriptions?.userSubscriptions?.find(
        (sub) => sub.id === subscriptionId
      );

      // Validate subscription is eligible for UbiTray selection
      if (
        subscription &&
        (!subscription.plan || subscription.plan.ask_choice !== true)
      ) {
        console.error(
          "This subscription plan does not support UbiTray selection"
        );
        return {
          success: false,
          error: "Bu abonelik planı UbiTray seçimine uygun değildir.",
        };
      }

      // Format as expected by the API
      const payload = {
        chosen_ubi_trays: ubiTrays,
      };

      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/subscription/subscriptions/${subscriptionId}/`,
        payload,
        config
      );

      // Refresh subscriptions after update
      dispatch(getUserSubscriptions());

      return { success: true, data: res.data };
    } catch (err) {
      console.error(
        "Error updating UbiTrays:",
        err.response ? err.response.data : err
      );
      return {
        success: false,
        error: err.response ? err.response.data : "Network error",
      };
    }
  };
