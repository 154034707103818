import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Grid,
  Select,
  MenuItem,
  Alert,
  Chip,
  CircularProgress,
} from "@mui/material";
import { connect } from "react-redux";
import { get_ubitray } from "../actions/products";
import { updateSubscriptionUbiTrays } from "../actions/subscriptions";

const SubscriptionUbiTrayEditor = ({
  open,
  onClose,
  subscription,
  get_ubitray,
  updateSubscriptionUbiTrays,
  ubiTrayProducts,
}) => {
  const [selectedTrayQuantities, setSelectedTrayQuantities] = useState({});
  const [activeTray, setActiveTray] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveError, setSaveError] = useState(null);

  // Calculate total quantity of selected trays
  const totalQuantity = Object.values(selectedTrayQuantities).reduce(
    (acc, qty) => acc + qty,
    0
  );

  // Validate subscription is eligible for UbiTray selection
  useEffect(() => {
    // Only check if plan is missing or doesn't have ask_choice
    if (
      subscription &&
      (!subscription.plan || subscription.plan.ask_choice !== true)
    ) {
      onClose(false);
    }
  }, [subscription, onClose]);

  // Load UbiTray products on mount
  useEffect(() => {
    get_ubitray();
  }, [get_ubitray]);

  // Initialize selected trays from subscription data
  useEffect(() => {
    if (subscription && subscription.chosen_ubi_trays) {
      setSelectedTrayQuantities(subscription.chosen_ubi_trays);
    } else {
      setSelectedTrayQuantities({});
    }
  }, [subscription]);

  // Select the first UbiTray product as active by default
  useEffect(() => {
    if (ubiTrayProducts && ubiTrayProducts.length > 0 && !activeTray) {
      setActiveTray(ubiTrayProducts[0]);
    }
  }, [ubiTrayProducts, activeTray]);

  const handleQuantityChange = (trayId, value) => {
    const quantity = parseInt(value, 10);
    setSelectedTrayQuantities((prev) => ({ ...prev, [trayId]: quantity }));
  };

  const handleClickTrayName = (tray) => {
    setActiveTray(tray);
  };

  const handleSave = async () => {
    // Enforce exactly 3 UbiTrays
    if (totalQuantity !== 3) {
      setSaveError("Toplam 3 adet UbiTray seçmelisiniz.");
      return;
    }

    // Filter out trays with zero quantity
    const filteredSelection = Object.entries(selectedTrayQuantities)
      .filter(([_, qty]) => qty > 0)
      .reduce((acc, [id, qty]) => ({ ...acc, [id]: qty }), {});

    try {
      setLoading(true);
      setSaveError(null);

      const result = await updateSubscriptionUbiTrays(
        subscription.id,
        filteredSelection
      );

      if (result.success) {
        onClose(true); // Close with success
      } else {
        setSaveError(
          result.error?.error ||
            "UbiTray seçimleri güncellenirken bir hata oluştu."
        );
      }
    } catch (error) {
      console.error("Error saving UbiTray selections:", error);
      setSaveError("Bir hata oluştu. Lütfen tekrar deneyin.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    onClose(false); // Close without saving
  };

  const getSelectedTrays = () => {
    // Check if ubiTrayProducts exists before using it
    if (!ubiTrayProducts) {
      return [];
    }

    return Object.entries(selectedTrayQuantities)
      .filter(([_, qty]) => qty > 0)
      .map(([id, qty]) => {
        const tray = ubiTrayProducts.find(
          (t) => t.id.toString() === id.toString()
        );
        // Use the real name if found, otherwise fallback to ID
        return {
          ...tray,
          id,
          name: tray ? tray.name : `UbiTray #${id}`,
          quantity: qty,
        };
      });
  };

  const isOverLimit = totalQuantity > 3;
  const isExactlyThree = totalQuantity === 3;

  // Early return if subscription is not eligible due to missing plan or ask_choice
  if (
    !subscription ||
    !subscription.plan ||
    subscription.plan.ask_choice !== true
  ) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth="md"
      fullWidth
      aria-labelledby="ubitray-selection-dialog"
    >
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="h6">
            UbiTray Seçimini Güncelle ({totalQuantity}/3)
          </Typography>
          {isExactlyThree && (
            <Chip label="Tamamlandı" color="success" size="small" />
          )}
          {isOverLimit && (
            <Chip label="Fazla Seçim" color="error" size="small" />
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2, p: 2 }}>
          {saveError && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {saveError}
            </Alert>
          )}

          {isExactlyThree ? (
            <Alert severity="success" sx={{ mb: 1 }}>
              Tam olarak 3 UbiTray seçildi. Kaydedebilirsiniz.
            </Alert>
          ) : isOverLimit ? (
            <Alert severity="error" sx={{ mb: 1 }}>
              {`Fazla seçim yaptınız! Lütfen toplam 3 adet UbiTray seçiniz. Şu an: ${totalQuantity}`}
            </Alert>
          ) : (
            <Alert severity="warning" sx={{ mb: 1 }}>
              {`Daha fazla seçim yapmalısınız. Lütfen toplamda 3 adet UbiTray seçiniz. Şu an: ${totalQuantity}`}
            </Alert>
          )}

          <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
            Bu aboneliğiniz için, her teslimat döneminde alacağınız UbiTray'leri
            seçebilirsiniz. Her teslimat döneminde toplam 3 adet UbiTray
            alacaksınız.
          </Typography>

          {totalQuantity > 0 && (
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography variant="body2">Seçilen UbiTray'ler:</Typography>
              {getSelectedTrays().map((tray) => (
                <Chip
                  key={tray.id}
                  label={`${tray.name} (${tray.quantity})`}
                  color={isOverLimit ? "error" : "primary"}
                  size="small"
                  onDelete={() => handleQuantityChange(tray.id, 0)}
                />
              ))}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            height: { xs: "auto", md: 550 },
            overflow: "hidden",
          }}
        >
          {/* LEFT side: Tray list */}
          <Box
            sx={{
              width: { xs: "100%", md: "30%" },
              overflowY: "auto",
              borderRight: { xs: 0, md: 1 },
              borderBottom: { xs: 1, md: 0 },
              borderColor: "divider",
              mb: { xs: 2, md: 0 },
              mr: { xs: 0, md: 2 },
              pb: { xs: 2, md: 0 },
            }}
          >
            <Typography variant="body1" gutterBottom>
              Toplam Seçim: {totalQuantity} adet
              {isExactlyThree && (
                <Chip
                  label="Tamamlandı"
                  color="success"
                  size="small"
                  sx={{ ml: 1 }}
                />
              )}
            </Typography>
            {!ubiTrayProducts || ubiTrayProducts.length === 0 ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <List dense>
                {ubiTrayProducts.map((tray) => {
                  const quantity = selectedTrayQuantities[tray.id] || 0;
                  const isOutOfStock = tray.quantity <= 0;
                  return (
                    <ListItem
                      key={tray.id}
                      button
                      onClick={() => handleClickTrayName(tray)}
                      selected={activeTray && activeTray.id === tray.id}
                      sx={isOutOfStock ? { opacity: 0.6 } : {}}
                    >
                      <ListItemText
                        primary={
                          <>
                            {tray.name}
                            {isOutOfStock && (
                              <Chip
                                label="Stokta Yok"
                                color="error"
                                size="small"
                                sx={{ ml: 1, height: 20, fontSize: "0.7rem" }}
                              />
                            )}
                          </>
                        }
                        secondary={
                          tray.adet_tohum
                            ? `${tray.adet_tohum} Bitki`
                            : "Bilinmiyor"
                        }
                      />
                      <Box sx={{ width: 80 }}>
                        <Select
                          value={quantity}
                          onChange={(e) =>
                            handleQuantityChange(tray.id, e.target.value)
                          }
                          size="small"
                          sx={{ width: "100%" }}
                          disabled={isOutOfStock}
                        >
                          {[0, 1, 2, 3].map((num) => (
                            <MenuItem key={num} value={num}>
                              {num}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Box>

          {/* RIGHT side: Tray details */}
          <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
            {activeTray ? (
              <>
                <Typography variant="h6" gutterBottom>
                  {activeTray.name}
                </Typography>
                {activeTray.image && (
                  <img
                    src={activeTray.image}
                    alt={activeTray.name}
                    style={{
                      width: "100%",
                      maxHeight: 220,
                      objectFit: "cover",
                    }}
                  />
                )}
                <Divider sx={{ my: 2 }} />
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Ürün Bilgileri
                  </Typography>
                  <Typography variant="body2" paragraph>
                    {activeTray.description ||
                      "Bu ürün için açıklama bulunmuyor."}
                  </Typography>

                  <Grid container spacing={2}>
                    {activeTray.adet_tohum && (
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          Bitki Sayısı
                        </Typography>
                        <Typography variant="body2">
                          {activeTray.adet_tohum}
                        </Typography>
                      </Grid>
                    )}

                    {activeTray.quantity > 0 ? (
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">Stok Durumu</Typography>
                        <Typography variant="body2" color="success.main">
                          Stokta Mevcut
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">Stok Durumu</Typography>
                        <Typography variant="body2" color="error">
                          Stokta Yok
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Sol taraftan bir UbiTray seçin
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="inherit">
          İptal
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          disabled={!isExactlyThree || loading}
          variant="contained"
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          {loading ? "Kaydediliyor..." : "Kaydet"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  ubiTrayProducts: state.products.ubiTrayProducts,
});

export default connect(mapStateToProps, {
  get_ubitray,
  updateSubscriptionUbiTrays,
})(SubscriptionUbiTrayEditor);
