import {
  GET_PAYMENT_TOTAL_SUCCESS,
  GET_PAYMENT_TOTAL_FAIL,
  LOAD_BT_TOKEN_SUCCESS,
  LOAD_BT_TOKEN_FAIL,
  PAYMENT_SUCCESS,
  PAYMENT_FAIL,
  RESET_PAYMENT_INFO,
  SET_PAYMENT_LOADING,
  REMOVE_PAYMENT_LOADING,
  SEND_TO_3D_SUCCESS,
  SEND_TO_3D_FAIL,
  MOKA_DIRECT_PAYMENT_SUCCESS,
  MOKA_DIRECT_PAYMENT_FAIL,
} from "../actions/types";

const initialState = {
  clientToken: null,
  clientSecret: null,
  made_payment: false,
  totalCost: 0.0,
  oneTimeTotal: 0.0,
  subscriptionTotal: 0.0,
  shippingCost: 0.0,
  loading: false,
  sent_to_3d: false,
};

export default function payment(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PAYMENT_TOTAL_SUCCESS:
      return {
        ...state,
        totalCost:
          typeof payload.total_amount === "number"
            ? payload.total_amount
            : parseFloat(payload.total_amount) || 0.0,
        oneTimeTotal:
          typeof payload.original_price === "number"
            ? payload.original_price
            : parseFloat(payload.original_price) || 0.0,
        subscriptionTotal:
          typeof payload.subscription_total === "number"
            ? payload.subscription_total
            : parseFloat(payload.subscription_total) || 0.0,
        shippingCost:
          typeof payload.shipping_cost === "number"
            ? payload.shipping_cost
            : parseFloat(payload.shipping_cost) || 0.0,
      };
    case GET_PAYMENT_TOTAL_FAIL:
      return {
        ...state,
        totalCost: 0.0,
        oneTimeTotal: 0.0,
        subscriptionTotal: 0.0,
        shippingCost: 0.0,
      };
    case MOKA_DIRECT_PAYMENT_SUCCESS:
      return {
        ...state,
        made_payment: true,
      };
    case MOKA_DIRECT_PAYMENT_FAIL:
      return {
        ...state,
        made_payment: false,
      };
    case SEND_TO_3D_SUCCESS:
      return {
        ...state,
        sent_to_3d: true,
      };
    case SEND_TO_3D_FAIL:
      return {
        ...state,
        sent_to_3d: false,
      };
    case LOAD_BT_TOKEN_SUCCESS:
      return {
        ...state,
        clientToken: payload.braintree_token,
      };
    case LOAD_BT_TOKEN_FAIL:
      return {
        ...state,
        clientToken: null,
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        made_payment: true,
      };
    case PAYMENT_FAIL:
      return {
        ...state,
        made_payment: false,
      };
    case SET_PAYMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_PAYMENT_LOADING:
      return {
        ...state,
        loading: false,
      };
    case RESET_PAYMENT_INFO:
      return {
        ...state,
        made_payment: false,
        clientToken: null,
      };
    default:
      return state;
  }
}
