import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  Rating,
  Button,
  Alert,
} from "@mui/material";
import axios from "axios";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch } from "react-redux";
import { setAlert } from "../actions/alert";

const CreateReview = ({ productId, onReviewCreated }) => {
  const dispatch = useDispatch();

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [images, setImages] = useState([]); // Array to hold selected image files
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Check if the user is authenticated by verifying if an access token exists.
  const isAuthenticated = Boolean(localStorage.getItem("access"));

  // Handle single image selection; add the image to the images array.
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (images.length >= 3) {
      setError("Maksimum 3 görsel yükleyebilirsiniz.");
      return;
    }
    setImages((prevImages) => [...prevImages, file]);
    setError("");
    // Reset the file input so the same file can be selected again if needed.
    e.target.value = null;
  };

  // Handle form submission.
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (rating <= 0) {
      setError("Lütfen bir puan verin.");
      return;
    }
    // Comment is optional.

    setLoading(true);
    try {
      // Prepare FormData to handle file uploads.
      const formData = new FormData();
      formData.append("rating", rating);
      formData.append("comment", comment);
      formData.append("product", productId); // Ensure this matches your backend expectations.

      // Append each image file.
      images.forEach((file) => {
        formData.append("images", file);
      });

      // Build Axios config with authentication header.
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          ...(localStorage.getItem("access") && {
            Authorization: `JWT ${localStorage.getItem("access")}`,
          }),
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reviews/create/`,
        formData,
        config
      );

      // Notify the parent component about the new review.
      if (onReviewCreated) {
        onReviewCreated(response.data);
      }

      // Dispatch a success alert to show a success snackbar.
      dispatch(setAlert("Yorumunuz başarıyla gönderildi.", "success"));

      // Clear the form.
      setRating(0);
      setComment("");
      setImages([]);
    } catch (err) {
      console.error(err);
      setError("Yorum gönderilirken bir hata oluştu.");
    }
    setLoading(false);
  };

  // If the user is not authenticated, show a message.
  if (!isAuthenticated) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Alert severity="info" sx={{ mt: 2 }}>
          Yorum yazmak için giriş yapmalısınız.
        </Alert>
      </Grid>
    );
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        mb={3}
        p={2}
        pt={4}
        pb={4}
        border="1px solid #e0e0e0"
        borderRadius="10px"
        maxWidth={800}
        width="80%"
      >
        <Typography variant="h6">Yorum Oluştur</Typography>
        {error && (
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Rating
            name="rating"
            value={rating}
            onChange={(event, newValue) => setRating(newValue)}
            size="large"
          />
        </Box>
        <TextField
          label="Yorum (Opsiyonel)"
          fullWidth
          multiline
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          sx={{ mt: 4, mb: 4 }}
        />
        <Button
          variant="contained"
          component="label"
          startIcon={<AddPhotoAlternateIcon />}
          sx={{ mt: 2, mr: 2, textTransform: "none" }}
        >
          Görsel Ekle (Maksimum 3)
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleImageChange}
          />
        </Button>
        {images.length > 0 && (
          <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
            {images.map((file, index) => (
              <Box key={index}>
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Önizleme ${index + 1}`}
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    borderRadius: "4px",
                  }}
                />
              </Box>
            ))}
          </Box>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          loading={loading}
          loadingIndicator="Gönderiliyor..."
          loadingPosition="end"
          sx={{ mt: 2 }}
        >
          Gönder
        </Button>
      </Box>
    </Grid>
  );
};

export default CreateReview;
