import React, { useState, useEffect } from "react";
import {
  Box,
  CardMedia,
  IconButton,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

const ImageSlider = ({ images, initialIndex, open, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(initialIndex || 0);

  useEffect(() => {
    setCurrentImageIndex(initialIndex);
  }, [initialIndex]);

  if (!images || images.length === 0) {
    return <Typography>Görsel Bulunamadı</Typography>;
  }

  const totalImages = images.length;

  const handlePrevClick = () => {
    setCurrentImageIndex(
      currentImageIndex === 0 ? totalImages - 1 : currentImageIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex(
      currentImageIndex === totalImages - 1 ? 0 : currentImageIndex + 1
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <AppBar
        sx={{ position: "relative" }}
        style={{ backgroundColor: "#fff" }}
        elevation={0}
      >
        <Toolbar style={{ justifyContent: "flex-end" }}>
          <IconButton edge="start" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box
          position="relative"
          width="100%"
          height="70vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
        >
          <CardMedia
            component="img"
            image={images[currentImageIndex]}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
          {totalImages > 1 && (
            <>
              <IconButton
                aria-label="previous"
                onClick={handlePrevClick}
                style={{
                  position: "absolute",
                  left: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
              >
                <ChevronLeft style={{ fontSize: "2rem" }} />
              </IconButton>
              <IconButton
                aria-label="next"
                onClick={handleNextClick}
                style={{
                  position: "absolute",
                  right: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
              >
                <ChevronRight style={{ fontSize: "2rem" }} />
              </IconButton>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImageSlider;
