import React, { useState } from "react";
import {
  Box,
  CardMedia,
  IconButton,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

const ImageSlider = ({ product, images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  // Fallback if no images provided
  if (!images || images.length === 0) {
    return <Typography>Görsel Bulunamadı</Typography>;
  }

  const totalImages = images.length;

  const handlePrevClick = () => {
    setCurrentImageIndex(
      currentImageIndex === 0 ? totalImages - 1 : currentImageIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex(
      currentImageIndex === totalImages - 1 ? 0 : currentImageIndex + 1
    );
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      {/* {product && product.presale_price && (
        <Grid
          container
          style={{
            width: "95%",
            padding: 10,
            borderRadius: 10,
            marginBottom: 10,
            justifyContent: "flex-end",
          }}
        >
          <Typography
            style={{
              background: "linear-gradient(90deg, #4B9DF6, #5DD36E)",
              color: "white",
              padding: 10,
              borderRadius: 4,
            }}
          >
            {Math.round(
              ((product.presale_price - product.price) /
                product.presale_price) *
                100
            )}
            % İNDİRİM
          </Typography>
        </Grid>
      )} */}

      {/* Main slider container with responsive height and positioning */}
      <Box
        position="relative"
        width="100%"
        height={isMobile ? 350 : 600} // Reduced height on mobile
        overflow="hidden" // ensures images do not overflow
        sx={{
          maxWidth: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CardMedia
          component="img"
          image={images[currentImageIndex]}
          onClick={handleDialogOpen}
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
            objectFit: "contain", // maintains aspect ratio and fits the container
            padding: isMobile ? 0 : "inherit", // Remove padding on mobile
          }}
        />
        {/* Previous Button */}
        <IconButton
          aria-label="previous"
          onClick={handlePrevClick}
          style={{
            position: "absolute",
            left: isMobile ? 5 : 10,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <ChevronLeft
            style={{
              color: "black",
              background: "white",
              borderRadius: "50%",
              padding: 5,
              fontSize: isMobile ? "1.5rem" : "2rem",
            }}
            fontSize={isMobile ? "medium" : "large"}
          />
        </IconButton>
        {/* Next Button */}
        <IconButton
          aria-label="next"
          onClick={handleNextClick}
          style={{
            position: "absolute",
            right: isMobile ? 5 : 10,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <ChevronRight
            style={{
              color: "black",
              background: "white",
              borderRadius: "50%",
              padding: 5,
              fontSize: isMobile ? "1.5rem" : "2rem",
            }}
            fontSize={isMobile ? "medium" : "large"}
          />
        </IconButton>
      </Box>

      {/* Dialog slider with a similar approach */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullScreen>
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: "#fff" }}
          elevation={0}
        >
          <Toolbar style={{ justifyContent: "flex-end" }}>
            <IconButton
              edge="start"
              onClick={handleDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box
            position="relative"
            width="100%"
            height="70vh" // fixed height for the dialog slider
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
          >
            <CardMedia
              component="img"
              image={images[currentImageIndex]}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
            <IconButton
              aria-label="previous"
              onClick={handlePrevClick}
              style={{
                position: "absolute",
                left: 10,
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 1,
              }}
            >
              <ChevronLeft style={{ fontSize: "2rem" }} />
            </IconButton>
            <IconButton
              aria-label="next"
              onClick={handleNextClick}
              style={{
                position: "absolute",
                right: 10,
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 1,
              }}
            >
              <ChevronRight style={{ fontSize: "2rem" }} />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageSlider;
