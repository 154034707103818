import React, { useState, useEffect, useMemo } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { get_product, get_ubitray } from "../actions/products";
import {
  add_item,
  get_items,
  get_total,
  get_item_total,
} from "../actions/cart";
import {
  getProductSubscriptionPlans,
  createSubscription,
  addSubscriptionItem,
} from "../actions/subscriptions";
import ProductDetailCard from "../components/ProductDetailCard";
import ProductDetailCardWithOptions from "../components/ProductDetailCardWithOptions";
import ProductReviews from "../components/ProductReviews";
import CreateReview from "../components/CreateReview";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Grid, CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";

const ProductDetail = ({
  match,
  product,
  get_product,
  add_item,
  get_items,
  get_total,
  get_item_total,
  subscriptionPlans,
  getProductSubscriptionPlans,
  get_ubitray,
  ubiTrayProducts,
}) => {
  const [redirect, setRedirect] = useState(false);
  const [reviewKey, setReviewKey] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(true);
  const [reviewsError, setReviewsError] = useState(null);

  // Compute total ratings and average rating from reviews
  const { totalRatings, averageRating } = useMemo(() => {
    if (reviews.length === 0) return { totalRatings: 0, averageRating: 0 };
    const totalRatings = reviews.length;
    const sumRatings = reviews.reduce((acc, review) => acc + review.rating, 0);
    const averageRating = (sumRatings / totalRatings).toFixed(1);
    return { totalRatings, averageRating };
  }, [reviews]);

  const handleReviewCreated = (newReview) => {
    // Update the key to force the ProductReviews component to refresh its data
    setReviewKey((prevKey) => prevKey + 1);
    // Also refresh the reviews from the API
    fetchReviews(product?.slug);
  };

  // Grab product ID (or slug) from the URL
  const productId = match.params.id;

  // Function to fetch reviews
  const fetchReviews = async (slug) => {
    if (!slug) return;

    setReviewsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/reviews/product/${slug}/`,
        {
          headers: { Accept: "application/json" },
        }
      );
      const reviewsData = Array.isArray(res.data) ? res.data : res.data.reviews;
      setReviews(reviewsData || []);
    } catch (err) {
      setReviewsError("Error fetching reviews.");
    }
    setReviewsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // 1) Load the product
    get_product(productId);

    // 2) Load subscription plans for this product
    getProductSubscriptionPlans(productId);

    // 3) Load UbiTray products
    get_ubitray();
  }, [productId, get_product, getProductSubscriptionPlans, get_ubitray]);

  // Fetch reviews when product slug is available
  useEffect(() => {
    if (product?.slug) {
      fetchReviews(product.slug);
    }
  }, [product?.slug]);

  if (redirect) return <Redirect to="/cart-or-continue-shopping" />;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {product?.name
            ? `${product.name} Detay - Ubicro`
            : "Ubicro Ürün Detay"}
        </title>
      </Helmet>
      <Header />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ maxWidth: 1600, margin: "0 auto" }}
      >
        <ProductDetailCardWithOptions
          product={product}
          add_item={add_item}
          get_items={get_items}
          get_total={get_total}
          get_item_total={get_item_total}
          setRedirect={setRedirect}
          subscriptionPlans={subscriptionPlans}
          createSubscription={createSubscription}
          addSubscriptionItem={addSubscriptionItem}
          ubiTrayProducts={ubiTrayProducts}
          reviews={reviews}
          reviewsLoading={reviewsLoading}
          averageRating={averageRating}
          totalRatings={totalRatings}
        />
        <ProductDetailCard product={product} />
      </Grid>

      {product?.slug && (
        <ProductReviews
          key={reviewKey}
          productSlug={product.slug}
          reviews={reviews}
          loading={reviewsLoading}
          error={reviewsError}
        />
      )}

      {product && (
        <CreateReview
          productId={product.id}
          onReviewCreated={handleReviewCreated}
        />
      )}
      <Grid style={{ marginTop: 150 }} />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const productId = ownProps.match.params.id;

  return {
    product: state.products.product,
    // Our subscriptionPlans are stored in `state.subscriptions.productPlans[productId]`
    subscriptionPlans: state.subscriptions.productPlans[productId] || [],
    ubiTrayProducts: state.products.ubiTrayProducts,
  };
};

export default connect(mapStateToProps, {
  get_product,
  getProductSubscriptionPlans,
  createSubscription,
  addSubscriptionItem,
  add_item,
  get_items,
  get_total,
  get_item_total,
  get_ubitray,
})(ProductDetail);
